<template>
  <validation-observer ref="observer">
    <v-form>
      <v-fade-transition>
        <v-overlay v-if="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-fade-transition>

      <v-expansion-panels v-model="panels" multiple>
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            {{ channelName }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <section class="px-6">
              <v-row no-gutters>
                <!-- CAMPO DE SELECCION DE PAIS -->
                <v-col cols="12" md="7" class="pr-2">
                  <h4 class="my-6">Selecciona el País</h4>
                  <validation-provider
                    v-slot="{ errors }"
                    name="País"
                    rules="required"
                  >
                    <v-select
                      :items="GetCountries"
                      v-model="form[GetGeneralInfoKeys.country.getKey]"
                      :item-text="GetGeneralInfoKeys.country.text"
                      :item-value="GetGeneralInfoKeys.country.value"
                      :error-messages="errors"
                      outlined
                      @input="selectedCountry"
                      label="País"
                    ></v-select>
                  </validation-provider>
                </v-col>

                <!-- CAMPOS DE CONDICIONES GENERALES -->
                <v-col cols="12" md="5" class="pl-2 text-center">
                  <h4 class="mt-6">Condicionales del canal</h4>
                  <div class="px-3">
                    <!-- Habilitar venta del canal -->
                    <v-switch
                      v-model="form[GetGeneralInfoKeys.sale]"
                      inset
                      dense
                      hide-details
                      color="success"
                      vertical
                      label="Habilitar para la venta"
                    ></v-switch>
                    <!-- Venta de terceros -->
                    <v-switch
                      v-model="form[GetGeneralInfoKeys.assistedSale]"
                      inset
                      dense
                      hide-details
                      color="primary"
                      label="Venta de Terceros"
                    ></v-switch>
                  </div>
                </v-col>
              </v-row>
            </section>
          </v-expansion-panel-content>

          <!-- SECCION TIPO DE CANAL -->
          <v-expansion-panel-header :class="panelClasses">
            Tipo de Canal
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <validation-provider
              v-slot="{ errors }"
              name="tipo de canal"
              rules="required"
            >
              <v-radio-group
                :value="form[GetGeneralInfoKeys.channelType.getKey]"
                mandatory
                @change="handlerSelectChannelType"
              >
                <v-row class="pt-6 px-6">
                  <v-col
                    cols="12"
                    md="5"
                    v-for="(type, i) of GetChannelsType"
                    :key="i"
                    :order="
                      type.codigo == 'SUCURSAL'
                        ? 3
                        : type.codigo == 'CONCESIONARIO'
                        ? 2
                        : 1
                    "
                  >
                    <v-radio
                      :label="type[GetGeneralInfoKeys.channelType.text]"
                      :value="type[GetGeneralInfoKeys.channelType.value]"
                      :error="errors.length > 0"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
              <p class="caption error--text" v-if="errors.length > 0">
                Debe seleccionar al menos 1 tipo de canal.
              </p>
            </validation-provider>

            <v-divider class="my-6"></v-divider>
            <!-- DATOS ADICIONALES DE TIPO CANAL -->
            <!-- Tipo consecionario -->
            <v-slide-y-transition>
              <div v-if="channelType == 'CONCESIONARIO'">
                <h4 class="mt-6 caption">
                  Información requerida para el tipo de canal.
                </h4>
                <v-row dense>
                  {{ form[GetGeneralInfoKeys.country.text] }}
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Rut Consecionario"
                      rules="required"
                    >
                      <v-text-field
                        v-if="descCountry === 'PERU'"
                        v-model="
                          form[GetGeneralInfoKeys.concessionaire.key][
                            GetGeneralInfoKeys.concessionaire.documentNumber
                          ]
                        "
                        name="rucConsecionario"
                        :error-messages="errors"
                        outlined
                        label="RUC"
                        dense
                      ></v-text-field>
                      <v-text-field
                        v-else
                        v-model="
                          form[GetGeneralInfoKeys.concessionaire.key][
                            GetGeneralInfoKeys.concessionaire.documentNumber
                          ]
                        "
                        name="rutConsecionario"
                        :error-messages="errors"
                        outlined
                        label="RUT"
                        dense
                        :rules="validRut"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Razon Social"
                      rules="required"
                    >
                      <v-text-field
                        v-model="
                          form[GetGeneralInfoKeys.concessionaire.key][
                            GetGeneralInfoKeys.concessionaire.title
                          ]
                        "
                        name="razonSocial"
                        :error-messages="errors"
                        outlined
                        label="Razón Social"
                        dense
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
            </v-slide-y-transition>

            <!-- SECCION CONTACTO -->
            <h4 v-if="channelManager.length > 0">Responsable del Canal</h4>
            <v-row>
              <v-col cols="12" md="6" v-for="(m, i) in channelManager" :key="i">
                <v-card class="elevation-0">
                  <v-list-item three-line>
                    <v-list-item-avatar size="80" color="grey">
                      <v-avatar size="82" color="secondary">
                        <img
                          :src="`https://avatars.dicebear.com/api/avataaars/${
                            m[GetGeneralInfoKeys.manager.email]
                          }.svg`"
                          :alt="`${m[GetGeneralInfoKeys.manager.firstName]} ${
                            m[GetGeneralInfoKeys.manager.lastName]
                          }`"
                        />
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h6 mb-1 font-weight-bold">
                        {{ m[GetGeneralInfoKeys.manager.firstName] }}
                        {{ m[GetGeneralInfoKeys.manager.lastName] }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Email -
                        {{
                          m[GetGeneralInfoKeys.manager.email]
                        }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        >Telefóno -
                        {{
                          m[GetGeneralInfoKeys.manager.phone]
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- SECCION UBICACION Y CONTACTO -->
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            Contacto y ubicación del canal
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-6">
            <v-row class="px-3" dense>
              <v-col cols="12" md="6">
                <h4>Persona de contacto</h4>
                <validation-provider
                  v-slot="{ errors }"
                  name="persona de contacto"
                  rules="required"
                >
                  <v-text-field
                    v-model="
                      form[GetGeneralInfoKeys.contact.key][
                        GetGeneralInfoKeys.contact.person
                      ]
                    "
                    name="persona_de_contacto"
                    :error-messages="errors"
                    id="id_persona_de_contacto"
                    outlined
                    dense
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <h4>Email</h4>
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="
                      form[GetGeneralInfoKeys.contact.key][
                        GetGeneralInfoKeys.contact.personEmail
                      ]
                    "
                    name="email"
                    :error-messages="errors"
                    id="id_email"
                    dense
                    outlined
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <!-- Telefono de contacto -->
              <v-col cols="12" md="6">
                <h4>Teléfono</h4>
                <validation-provider
                  v-slot="{ errors }"
                  name="teléfono"
                  rules="required"
                >
                  <v-text-field
                    v-model="
                      form[GetGeneralInfoKeys.contact.key][
                        GetGeneralInfoKeys.contact.phone
                      ]
                    "
                    name="telefono"
                    id="id_telefono"
                    dense
                    :error-messages="errors"
                    outlined
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <!-- Direccion -->
              <v-col cols="12" md="6">
                <h4>Calle o dirección</h4>
                <validation-provider
                  v-slot="{ errors }"
                  name="dirección"
                  rules="required"
                >
                  <v-text-field
                    v-model="
                      form[GetGeneralInfoKeys.contact.key][
                        GetGeneralInfoKeys.contact.address
                      ]
                    "
                    name="direccion"
                    id="id_direccion"
                    :error-messages="errors"
                    dense
                    outlined
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <!-- Numero -->
              <v-col cols="12" md="3">
                <h4>Numero</h4>
                <validation-provider
                  v-slot="{ errors }"
                  name="numero"
                  rules="required"
                >
                  <v-text-field
                    v-model="form[GetGeneralInfoKeys.contact.home]"
                    dense
                    :error-messages="errors"
                    outlined
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <!-- Piso -->
              <v-col cols="12" md="3">
                <h4>Piso</h4>
                <validation-provider
                  v-slot="{ errors }"
                  name="piso"
                  rules="required"
                >
                  <v-text-field
                    v-model="form[GetGeneralInfoKeys.contact.floor]"
                    dense
                    :error-messages="errors"
                    outlined
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <!-- Comuna -->
              <v-col cols="12" md="6">
                <h4>Comuna</h4>
                <validation-provider
                  v-slot="{ errors }"
                  name="comuna"
                  rules="required"
                >
                  <v-autocomplete
                    :items="locations"
                    :value="form[GetGeneralInfoKeys.contact.location]"
                    item-text="label"
                    item-value="value"
                    dense
                    :error-messages="errors"
                    @change="handlerSetLocation"
                    outlined
                  ></v-autocomplete>
                </validation-provider>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- SECCION DE COMPANIAS -->
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            Compañias
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-6 px-6">
            <p class="subtitle-2">
              Seleccione las compañias relacionadas al canal. Cada compañía
              posee sus propios medios de pago con los cuales trabajar, puede
              habilitarlos o deshabilitarlos para el canal.
            </p>
            <validation-provider
              v-slot="{ errors }"
              name="compañias"
              rules="required"
            >
              <div v-for="(company, i) of GetCompanies" :key="i">
                <v-checkbox
                  :label="company[GetGeneralInfoKeys.company.text]"
                  @change="handlerSelectCompany(company)"
                  :input-value="companyValidator(company)"
                  :true-value="true"
                  :false-value="false"
                  :error="errors.length > 0"
                ></v-checkbox>
                <!-- Medios de pago de la compania -->
                <v-slide-y-transition mode="out-in" group>
                  <template v-if="companyValidator(company)">
                    <v-chip
                      v-for="(pay, x) in company[
                        GetGeneralInfoKeys.company.payment
                      ]"
                      :key="x"
                      :input-value="companyPaymentValidator(company, pay)"
                      filter
                      small
                      label
                      outlined
                      :color="
                        companyPaymentValidator(company, pay)
                          ? 'primary'
                          : undefined
                      "
                      class="mx-1"
                      link
                      @click="handlerSelectCompanyPayment(company, pay)"
                    >
                      {{
                        GetPaymentMethods.find(
                          (e) =>
                            e[GetGeneralInfoKeys.paymentMethod.value] == pay
                        )?.[GetGeneralInfoKeys.paymentMethod.text] ??
                        "Desconocido"
                      }}
                    </v-chip>
                    <p
                      class="caption grey--text text--darken-1 mb-0"
                      v-if="
                        company[GetGeneralInfoKeys.company.payment]?.length == 0
                      "
                      key="no-mp-config"
                    >
                      Esta compañia no tiene medios de pagos configurados.
                    </p>
                  </template>
                </v-slide-y-transition>
              </div>
              <p class="caption error--text" v-if="errors.length > 0">
                Debe seleccionar al menos 1 compañia.
              </p>
            </validation-provider>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- SECCION DE MEDIOS DE PAGO -->
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            Medios de Pago
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-6 px-6">
            <v-col
              cols="6"
              offset="6"
              md="5"
              offset-md="5"
              class="pa-1"
              v-if="!mobile"
            >
              <h4>Número de cuotas</h4>
            </v-col>
            <validation-provider
              v-slot="{ errors }"
              name="medios de pago"
              rules="required"
            >
              <template v-for="(pay, i) of GetPaymentMethods">
                <v-row no-gutters align="center" :key="i">
                  <v-col cols="12" md="5">
                    <v-checkbox
                      :label="pay[GetGeneralInfoKeys.paymentMethod.text]"
                      v-model="form.medios_de_pago"
                      :error="errors.length > 0"
                      :value="pay"
                      :value-comparator="handlerPaymentComparator"
                      multiple
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-row
                      no-gutters
                      v-if="
                        (pay[GetGeneralInfoKeys.paymentMethod.data.mainKey][
                          GetGeneralInfoKeys.paymentMethod.data.code
                        ] ||
                          pay[GetGeneralInfoKeys.paymentMethod.data.mainKey]) ==
                        GetGeneralInfoKeys.paymentMethod.data.bannual
                      "
                    >
                      <v-col class="px-1" cols="6">
                        <!-- <v-text-field
 outlined
 hide-details
 label="Cuota anual"
 :disabled="
 checkPaymentItem(
 pay[GetGeneralInfoKeys.paymentMethod.value]
 )
 "
 @input="
 setPaymentItem(
 $event,
 pay[GetGeneralInfoKeys.paymentMethod.value],
 GetGeneralInfoKeys.paymentMethod.data.annualRate
 )
 "
 ></v-text-field> -->
                        <v-slider
                          min="1"
                          max="99"
                          thumb-label
                          :value="
                            pay[
                              GetGeneralInfoKeys.paymentMethod.data.annualRate
                            ]
                          "
                          :hint="`Cuota anual: ${
                            pay[
                              GetGeneralInfoKeys.paymentMethod.data.annualRate
                            ]
                          }`"
                          persistent-hint
                          :disabled="
                            checkPaymentItem(
                              pay[GetGeneralInfoKeys.paymentMethod.value]
                            )
                          "
                          @input="
                            setPaymentItem(
                              $event,
                              pay[GetGeneralInfoKeys.paymentMethod.value],
                              GetGeneralInfoKeys.paymentMethod.data.annualRate
                            )
                          "
                        ></v-slider>
                      </v-col>
                      <v-col class="px-1" cols="6">
                        <!-- <v-text-field
 outlined
 hide-details
 label="Cuota bianual"
 :disabled="
 checkPaymentItem(
 pay[GetGeneralInfoKeys.paymentMethod.value]
 )
 "
 @input="
 setPaymentItem(
 $event,
 pay[GetGeneralInfoKeys.paymentMethod.value],
 GetGeneralInfoKeys.paymentMethod.data.bannualRate
 )
 "
 ></v-text-field> -->
                        <v-slider
                          min="1"
                          max="99"
                          thumb-label
                          :value="
                            pay[
                              GetGeneralInfoKeys.paymentMethod.data.bannualRate
                            ]
                          "
                          :hint="`Cuota bianual: ${
                            pay[
                              GetGeneralInfoKeys.paymentMethod.data.bannualRate
                            ]
                          }`"
                          persistent-hint
                          :disabled="
                            checkPaymentItem(
                              pay[GetGeneralInfoKeys.paymentMethod.value]
                            )
                          "
                          @input="
                            setPaymentItem(
                              $event,
                              pay[GetGeneralInfoKeys.paymentMethod.value],
                              GetGeneralInfoKeys.paymentMethod.data.bannualRate
                            )
                          "
                        ></v-slider>
                      </v-col>
                    </v-row>

                    <v-col
                      class="pa-0"
                      cols="6"
                      v-else-if="
                        (pay[GetGeneralInfoKeys.paymentMethod.data.mainKey][
                          GetGeneralInfoKeys.paymentMethod.data.code
                        ] ||
                          pay[GetGeneralInfoKeys.paymentMethod.data.mainKey]) ==
                        GetGeneralInfoKeys.paymentMethod.data.free
                      "
                    >
                      <!-- <v-text-field
 outlined
 hide-details
 label="Definir cuota"
 :disabled="
 checkPaymentItem(
 pay[GetGeneralInfoKeys.paymentMethod.value]
 )
 "
 @input="
 setPaymentItem(
 $event,
 pay[GetGeneralInfoKeys.paymentMethod.value],
 GetGeneralInfoKeys.paymentMethod.data.unlimitedRate
 )
 "
 type="number"
 ></v-text-field> -->
                      <v-slider
                        min="1"
                        max="99"
                        :value="
                          pay[
                            GetGeneralInfoKeys.paymentMethod.data.unlimitedRate
                          ]
                        "
                        thumb-label
                        :hint="`Definir cuota: ${
                          pay[
                            GetGeneralInfoKeys.paymentMethod.data.unlimitedRate
                          ]
                        }`"
                        persistent-hint
                        :disabled="
                          checkPaymentItem(
                            pay[GetGeneralInfoKeys.paymentMethod.value]
                          )
                        "
                        @input="
                          setPaymentItem(
                            $event,
                            pay[GetGeneralInfoKeys.paymentMethod.value],
                            GetGeneralInfoKeys.paymentMethod.data.unlimitedRate
                          )
                        "
                      ></v-slider>
                    </v-col>
                  </v-col>
                </v-row>
                <v-divider class="my-6" :key="`div_${i}`"></v-divider>
              </template>
              <p class="caption error--text" v-if="errors.length > 0">
                Debe seleccional al menos 1 método de pago.
              </p>
            </validation-provider>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- SECCION DE PERSONALIZACION CORPORATIVA -->
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            Personalización corporativa
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-6 px-6">
            <v-fade-transition mode="out-in">
              <template v-if="!customization.selected">
                <v-sheet
                  class="d-flex align-center justify-center selectable elevation-2"
                  title="Tema del canal"
                  height="315"
                  @click="handlerOpenCustomList"
                >
                  <p class="subtitle-1 font-weight-medium primary--text">
                    Haz clic aquí para seleccionar un tema para el canal
                  </p>

                  <!-- DESCOMENTAR SI EL CAMPO DE PERSONALIZACION ES REQUERIDO -->
                  <!-- <validation-provider
                    v-slot="{ errors }"
                    name="personalizacion"
                    rules="required"
                    >
                    <v-text-field
                    v-show="false"
                    v-model="form[GetCustomKeys.customized]"
                    ></v-text-field>
                    <p v-if="errors.length > 0" class="caption error--text">Debe seleccionar un tema corporativo</p>
                  </validation-provider> -->
                </v-sheet>
                <!-- <p v-else>Este canal no tiene personalización corporativa.</p> -->
              </template>
              <v-card
                v-else
                :height="mobile ? 540 : $vuetify.breakpoint.md ? 340 : 315"
              >
                <v-list two-line>
                  <v-subheader>Tema del canal</v-subheader>

                  <v-list-item :class="{ 'flex-column': mobile }">
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">{{
                        customization.selected[GetCustomKeys.name]
                      }}</v-list-item-title>
                      <!-- <v-list-item-subtitle class="subtitle-2">{{
                        customization.selected[GetCustomKeys.domain]
                      }}</v-list-item-subtitle> -->
                    </v-list-item-content>

                    <v-img
                      :src="GetLogo"
                      :max-width="mobile ? 200 : 230"
                      contain
                      max-height="95"
                    >
                      <template #placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <template>
                      <v-btn
                        @click="resetCustomization"
                        color="error"
                        text
                        small
                        v-if="mobile"
                        ><v-icon small>mdi-delete</v-icon>
                        <span>Desechar</span></v-btn
                      >
                    </template>
                  </v-list-item>
                </v-list>
                <v-divider class="my-3"></v-divider>

                <v-card-title class="subtitle-2 py-1 font-weight-light"
                  >Colores personalizados</v-card-title
                >

                <v-card-actions>
                  <v-chip-group column class="mr-6">
                    <v-chip
                      v-for="(color, i) in customization.selected[
                        GetCustomKeys.colors.getKey
                      ]"
                      :key="i"
                      :color="color[GetCustomKeys.colors.color]"
                      :class="[
                        'elevation-1',
                        isDarkColor(color[GetCustomKeys.colors.color])
                          ? 'white--text'
                          : 'grey--text text--darken-4',
                      ]"
                      :small="$vuetify.breakpoint.mdAndDown"
                    >
                      {{ color[GetCustomKeys.colors.text] }}
                    </v-chip>
                  </v-chip-group>

                  <v-spacer v-if="!mobile"></v-spacer>

                  <template>
                    <v-btn
                      @click="resetCustomization"
                      color="error"
                      text
                      small
                      v-if="!mobile"
                      ><v-icon small>mdi-delete</v-icon>
                      <span>Desechar</span></v-btn
                    >
                  </template>
                </v-card-actions>
              </v-card>
            </v-fade-transition>
            <p class="caption mt-3">
              Nota: puede que la vista previa del logo se muestre recortado en
              esta sección, sin embargo, este se mostrará con sus medidas
              originales en la pantalla principal del dominio.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <BaseModal
        v-model="customization.show"
        :loading="customization.loading"
        @close="customization.show = false"
      >
        <h4 class="mt-4 px-3">Selecciona un Tema para este Canal</h4>
        <div class="my-3 ml-3 mr-6">
          <v-text-field
            dense
            v-model="customization.filter"
            :append-outer-icon="'mdi-magnify-expand'"
            outlined
            clearable
            label="Buscar tema"
            type="text"
            hide-details
            @click:clear="(customization.filter = ''), setListData()"
            @click:append-outer="setListData()"
          ></v-text-field>
        </div>
        <v-divider class="mt-6"></v-divider>
        <CustomList
          :items="GetCustomList"
          :keys="GetCustomKeys"
          @select-item="handlerSelectCustomization"
        />
        <v-card-text>
          <v-pagination
            :value="customization.current"
            circle
            :length="customization.pages"
            :total-visible="7"
            @input="(customization.current = $event), setListData()"
          ></v-pagination>
        </v-card-text>
      </BaseModal>
    </v-form>
  </validation-observer>
</template>

<script>
import BaseModal from "@/components/Modal/BaseModal.vue";
import CustomList from "@/components/Lists/customizationList.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { generalInfoKeys } from "@/store/resources/channelkeys.js";

import { darkorlightStyle } from "@/store/resources/tools";
import { rutMask } from "@/store/resources/masks.js";
import { validate } from "rut.js";
// import { validarPermisoTipoCanal } from "@/store/resources/validate.js";
export default {
  name: "GeneralInfoForm",
  components: {
    BaseModal,
    CustomList,
  },
  props: {
    panelClasses: {
      type: String,
      default: "",
      description: "Clases CSS anexas a los paneles del formulario",
    },
    mobile: {
      type: Boolean,
      default: false,
      description:
        "Valor que define si el dispositivo de vista es mobile o escritorio",
    },
    channel: {
      type: String,
      default: "",
      description: "Id del canal seleccionado.",
    },
    channelName: {
      type: String,
      default: "",
      description: "Nombre del canal seleccionado",
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (!this.loading) {
          this.$emit("change");
        }
      },
    },
  },
  data: () => ({
    descCountry: "",
    loading: false,
    form: {
      pais: "",
      tipo_canal: [],
      [generalInfoKeys.contact.key]: {
        [generalInfoKeys.contact.address]: "",
        [generalInfoKeys.contact.phone]: "",
        [generalInfoKeys.contact.personEmail]: "",
        [generalInfoKeys.contact.person]: "",
      },

      // [generalInfoKeys.contact.address2]: "",
      [generalInfoKeys.contact.location]: "",
      [generalInfoKeys.contact.locationDescription]: "",
      [generalInfoKeys.contact.floor]: "",
      [generalInfoKeys.contact.home]: "",

      [generalInfoKeys.company.key]: [],
      medios_de_pago: [],
      personalizacion_corporativa: "",
      venta: false,
      venta_asistida: false,
      [generalInfoKeys.concessionaire.key]: {
        [generalInfoKeys.concessionaire.documentType]: "RUT",
        [generalInfoKeys.concessionaire.documentNumber]: "",
        [generalInfoKeys.concessionaire.title]: "",
      },
      /* participa_financiera: false,
      financiera: {
        financiera_id: "",
        financiera_descripcion: "",
      }, */
    },
    channelManager: [],
    channelType: "",
    customization: {
      show: false,
      selected: null,
      loading: false,
      current: 1,
      pages: 1,
      limit: 5,
      filter: "",
    },
    panels: [0, 1, 2, 3, 4],
    rules: {
      required: [(v) => !!v || "Este campo es obligatorio."],
      cuote: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 1 && v <= 999) return true;
        return "La cuota debe ser un numero entre 1 y 99.";
      },
    },
    validRut: [(v) => validate(v) || "RUT invalido"],
    participaFinanciera: false,
    financieraSelected: null,
    financieras: [],
    locations: [],
  }),
  created() {
    this.setup();
  },
  computed: {
    ...mapGetters("Channels", [
      "GetCountries",
      "GetChannelsType",
      "GetCompanies",
      "GetPaymentMethods",

      "GetGeneralInfoKeys",
    ]),
    ...mapGetters("Customization", [
      "GetCustomKeys",
      "GetCustomList",
      "GetLogo",
    ]),
    rutMask: () => rutMask,
    // Permisos por tipo de canal
    // channelTypePermission() {
    //   return validarPermisoTipoCanal(1, this.channelType);
    // },
    paginationKeys() {
      return this.$store.getters.GetPaginationKeys;
    },
    isDarkColor() {
      return darkorlightStyle;
    },
    errorMessages() {
      return this.$store.getters.GetErrorMessages;
    },
  },
  methods: {
    ...mapActions("Channels", [
      "REQUEST_CHANNEL_GENERAL_INFO",
      "REQUEST_CHANNEL_GENERAL_INFO_DATA",
      "REQUEST_FINANCIALS",
    ]),
    ...mapActions("Customization", [
      "REQUEST_CUSTOMIZATION_BY_ID",
      "REQUEST_CUSTOMIZATIONS",
    ]),
    ...mapActions("Concesionarios", ["GetLocations"]),
    ...mapMutations("Customization", ["SetLogo"]),

    setup(cond = { type: true }) {
      this.loading = true;
      this.resetData();
      this.REQUEST_CHANNEL_GENERAL_INFO()
        .then(async () => {
          let { data } = await this.REQUEST_CHANNEL_GENERAL_INFO_DATA(
            this.channel
          );
          const result = data.data.result;
          this.locations = await this.GetLocations();
          await this.setupCustomization(result);
          this.setupData(result, cond?.type ?? true);
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.loading = false));

      this.REQUEST_FINANCIALS()
        .then((response) => {
          this.financieras = response;
        })
        .catch();
    },
    resetData() {
      const keys = this.GetGeneralInfoKeys;
      // Pais
      this.form[keys.country.getKey] = "";
      // Tipo de canal
      this.form.tipo_canal = [];
      // Informacion de contacto
      this.form[keys.contact.key][keys.contact.address] = "";
      this.form[keys.contact.key][keys.contact.person] = "";
      this.form[keys.contact.key][keys.contact.personEmail] = "";
      this.form[keys.contact.key][keys.contact.phone] = "";

      this.form[keys.contact.home] = "";
      this.form[keys.contact.floor] = "";
      this.form[keys.contact.location] = "";
      this.form[keys.contact.locationDescription] = "";
      // Compania
      this.form[keys.company.key] = [];
      // Personalizacion corporativa
      this.form.personalizacion_corporativa = "";
      this.resetCustomization();
      // Medios de pago
      this.form[keys.paymentMethod.getKey] = [];
      // Venta y venta asistida
      this.form[keys.assistedSale] = false;
      this.form[keys.sale] = false;
      // Consecionario info
      this.form[generalInfoKeys.concessionaire.key][
        generalInfoKeys.concessionaire.documentType
      ] = "RUT";
      this.form[generalInfoKeys.concessionaire.key][
        generalInfoKeys.concessionaire.documentNumber
      ] = "";
      this.form[generalInfoKeys.concessionaire.key][
        generalInfoKeys.concessionaire.title
      ] = "";
    },
    setupData(data, setupType = true) {
      const keys = this.GetGeneralInfoKeys;

      this.form[keys.country.getKey] = data?.[keys.country.getKey] ?? "";
      this.selectedCountry(this.form[keys.country.getKey]);
      // Llenar los datos del tipo de canal
      if (setupType) {
        this.form.tipo_canal = data.tipo_canal;
        this.handlerSelectChannelType(data.tipo_canal);
      }
      this.form[keys.financial_part] = data?.[keys.financial_part] ?? false;
      // Llenar campo de concensionario (En caso que exista)
      this.form[keys.concessionaire.key][keys.concessionaire.documentNumber] =
        data?.[keys.concessionaire.key]?.[keys.concessionaire.documentNumber] ??
        "";
      this.form[keys.concessionaire.key][keys.concessionaire.title] =
        data?.[keys.concessionaire.key]?.[keys.concessionaire.title] ?? "";
      // Llenar datos de contacto
      this.form[keys.contact.key][keys.contact.address] =
        data?.[keys.contact.key]?.[keys.contact.address] ?? "";
      this.form[keys.contact.key][keys.contact.person] =
        data?.[keys.contact.key]?.[keys.contact.person] ?? "";
      this.form[keys.contact.key][keys.contact.personEmail] =
        data?.[keys.contact.key]?.[keys.contact.personEmail] ?? "";
      this.form[keys.contact.key][keys.contact.phone] =
        data?.[keys.contact.key]?.[keys.contact.phone] ?? "";

      this.form[keys.contact.home] = data?.[keys.contact.home] ?? "";
      this.form[keys.contact.floor] = data?.[keys.contact.floor] ?? "";
      this.form[keys.contact.location] = data?.[keys.contact.location] ?? "";
      this.form[keys.contact.locationDescription] =
        data?.[keys.contact.locationDescription] ?? "";

      // Llenar datos de companias
      this.form[keys.company.key] = [];
      data.companias.forEach((element) => {
        if (keys.company.value in element && keys.company.code in element) {
          if (typeof element == "string") {
            let selected = this.GetCompanies.find(
              (e) => e[keys.company.value] == element
            );
            if (selected) {
              this.fillCompanyData(selected);
            }
          } else {
            this.form[keys.company.key].push(element);
          }
        }
      });
      // Llenar datos de los medios de pago
      this.form[keys.paymentMethod.getKey] =
        data?.[keys.paymentMethod.getKey] ?? [];
      // Llenas condiciones Venta y Venta asistida
      this.form[keys.assistedSale] = data?.[keys.assistedSale] ?? false;
      this.form[keys.sale] = data?.[keys.sale] ?? false;

      this.channelManager = data[keys.manager.key];
    },
    setupCustomization(payload) {
      return new Promise((resolve) => {
        // -------------------- //
        // Verificar que haya algo de la personalizacion corporativa en la data
        if (
          (this.GetCustomKeys.customized in payload &&
            payload[this.GetCustomKeys.customized] &&
            payload[this.GetCustomKeys.customized] !== "") ||
          (this.GetCustomKeys.id in payload &&
            payload[this.GetCustomKeys.id] &&
            payload[this.GetCustomKeys.id] !== "")
        ) {
          // Objeto completo de la personalizacion corporativa del canal
          const customId =
            payload[this.GetCustomKeys.customized] ||
            payload[this.GetCustomKeys.id];
          // -------------------- //
          // Verificar si "customId" viene como el objeto completo
          // de la personalizacion corporativa
          if (typeof customId === "object" && customId !== null) {
            this.customization.selected = customId;
            // Se procesa la ruta del logo
            if (customId.logo) {
              this.$store
                .dispatch("REQUEST_FILE", this.customization.selected.logo.url)
                .then((response) => this.SetLogo(response.data.url));
            }
            this.form[this.GetCustomKeys.customized] =
              customId[this.GetCustomKeys.id];
            resolve("pass");
            // -------------------- //
            // o Como un String con unicamente el ID.
          } else if (typeof customId == "string") {
            // -------------------- //
            // Obtener el objeto completo de la personalizacion corporativa
            // del canal actual mediante su ID.
            // -------------------- //
            this.REQUEST_CUSTOMIZATION_BY_ID(customId)
              .then((data) => {
                this.customization.selected = data;
                this.form[this.GetCustomKeys.customized] =
                  data[this.GetCustomKeys.id];
              })
              .catch((message) => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: message,
                  top: true,
                  right: true,
                  color: "warning",
                });
                this.customization.selected = null;
                this.form[this.GetCustomKeys.customized] = "";
              })
              .finally(() => resolve("pass"));
            // -------------------- //
          }
          // -------------------- //
          // De lo contrario se restaura la personalizacion corporativa por defecto
        } else {
          this.resetCustomization();
          resolve("not-pass");
        }
        // -------------------- //
      });
    },
    resetCustomization() {
      this.form[this.GetCustomKeys.customized] = "";
      this.customization.selected = null;
      this.customization.filter = "";
      this.customization.current = 1;
    },
    setListData() {
      this.customization.loading = true;
      const payload = {
        limit: this.customization.limit,
        page: this.customization.current,
        filter: this.customization.filter,
      };
      this.REQUEST_CUSTOMIZATIONS(payload)
        .then((response) => {
          // Settear valores de la paginacion luego de la consulta
          this.customization.pages = response[this.paginationKeys.pages];
          this.customization.current = response[this.paginationKeys.current];
          this.customization.limit = response[this.paginationKeys.limit];
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.customization.loading = false));
    },

    handlerPaymentComparator(a, b) {
      return (
        a[this.GetGeneralInfoKeys.paymentMethod.value] ===
        b[this.GetGeneralInfoKeys.paymentMethod.value]
      );
    },
    handlerOpenCustomList() {
      this.customization.show = true;
      this.setListData();
    },

    async handlerSelectCustomization(item) {
      this.customization.loading = true;
      await this.setupCustomization(item);
      this.customization.show = false;
    },

    handlerSelectChannelType(item) {
      const keys = this.GetGeneralInfoKeys.channelType,
        channelType = this.GetChannelsType.find((e) => e[keys.value] == item);
      this.form[keys.getKey] = item;
      if (channelType) {
        // if (
        //   this.channelType == keys.typeLevels[1] &&
        //   channelType[keys.code] != keys.typeLevels[1]
        // ) {
        //   this.setup({ type: false });
        // }
        this.channelType = channelType[keys.code];
      }
    },
    /* handlerSelectFinanciera(item) {
      const selectFin = this.financieras.find((e) => e._id == item);
      if (selectFin) {
        this.form.financiera.financiera_id = selectFin._id;
        this.form.financiera.financiera_descripcion =
          selectFin.financiera_descripcion;
      }
    }, */
    handlerSetLocation(item) {
      const keys = this.GetGeneralInfoKeys.contact;
      const selected = this.locations.find((element) => element.value === item);
      this.form[keys.location] = item;
      this.form[keys.locationDescription] = selected?.label ?? "";
    },
    //COMPANY METHODS
    handlerSelectCompany(item) {
      let keys = this.GetGeneralInfoKeys.company,
        index;
      const companies = this.form[keys.key],
        selected = companies.find((e, i) => {
          index = i;
          return item[keys.value] == e[keys.value];
        });

      if (selected) {
        companies.splice(index, 1);
      } else {
        if (!(keys.code in item && keys.value in item)) {
          this.$store.commit("setSnackbar", {
            active: true,
            text: this.errorMessages["4301"],
            top: true,
            right: true,
            color: "warning darken-1",
          });
        }
        this.fillCompanyData(item);
      }
    },
    fillCompanyData(item) {
      let data = {};
      const keys = this.GetGeneralInfoKeys.company,
        payKeys = this.GetGeneralInfoKeys.paymentMethod;
      data[keys.value] = item[keys.value];
      data[keys.text] = item[keys.text];
      data[keys.code] = item[keys.code];
      data[keys.payment] = item[keys.payment].map((e) => {
        let paySelect = this.GetPaymentMethods.find(
          (pay) => pay[payKeys.value] == e
        );
        if (paySelect) {
          let payData = {};
          payData.id = paySelect[payKeys.value];
          payData[payKeys.code] = paySelect[payKeys.code] ?? "";
          payData[payKeys.text] = paySelect[payKeys.text];
          return payData;
        } else {
          return false;
        }
      });
      this.form[keys.key].push(data);
    },
    companyValidator(company) {
      const keys = this.GetGeneralInfoKeys.company;
      return this.form[keys.key].some(
        (e) => e[keys.value] == company[keys.value]
      );
    },
    companyPaymentFindArray(comp) {
      const keys = this.GetGeneralInfoKeys.company;
      return this.form[keys.key].find(
        (e) => e[keys.value] == comp[keys.value]
      )?.[keys.payment];
    },
    companyPaymentValidator(comp, pay) {
      return !!this.companyPaymentFindArray(comp)?.find((e) => e.id == pay);
    },
    handlerSelectCompanyPayment(comp, pay) {
      let index;
      const payArray = this.companyPaymentFindArray(comp),
        currentItem = payArray?.find((e, i) => {
          index = i;
          return e.id == pay;
        });
      if (currentItem) {
        payArray.splice(index, 1);
      } else {
        const payKeys = this.GetGeneralInfoKeys.paymentMethod;
        let paySelect = this.GetPaymentMethods.find(
          (e) => e[payKeys.value] == pay
        );
        if (paySelect) {
          let payData = {};
          payData.id = paySelect[payKeys.value];
          payData[payKeys.code] = paySelect[payKeys.code] ?? "";
          payData[payKeys.text] = paySelect[payKeys.text];
          payArray.push(payData);
        }
      }
    },
    // PAYMENT METHODS
    checkPaymentItem(value) {
      return (
        this.form.medios_de_pago.filter(
          (e) => e[this.GetGeneralInfoKeys.paymentMethod.value] === value
        ).length <= 0
      );
    },
    setPaymentItem(e, itemId, key) {
      try {
        this.form.medios_de_pago.find(
          (element) =>
            element[this.GetGeneralInfoKeys.paymentMethod.value] == itemId
        )[key] = e.toString();
      } catch {
        console.log("Cargando medio de pago...");
      }
    },
    selectedCountry(id) {
      this.descCountry = this.GetCountries.find(
        (p) => id === p.pais
      ).pais_descripcion;
    },
  },
};
</script>

<template>
  <div>
    <validation-observer ref="observer">
      <v-form>
        <v-fade-transition>
          <v-overlay v-if="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-fade-transition>

        <div class="d-flex mb-1">
          <v-spacer></v-spacer>
          <p class="mb-0 caption">
            <v-icon>mdi-close</v-icon>: Eliminar o deseleccionar
          </p>
          <v-divider class="mx-1" vertical></v-divider>
          <p class="mb-0 caption">
            <v-icon color="primary">mdi-label</v-icon>: Tiene deducibles
          </p>
          <v-divider class="mx-1" vertical></v-divider>
          <p class="mb-0 caption">
            <v-icon>mdi-select-all</v-icon>: Seleccionar todos
          </p>
        </div>

        <v-expansion-panels v-model="panels" multiple>
          <!-- SECCION DE MARCAS Y MODELOS -->
          <v-expansion-panel class="no-elevation">
            <v-expansion-panel-header :class="panelClasses">
              Marcas y modelos
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mt-3 py-3 align-center" no-gutters>
                <v-col cols="12" md="6">
                  <h4>¿Aplica las marcas y modelos?</h4>
                  <p class="caption mb-0">
                    Habilitar las marcas y modelos para el canal.
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    v-model="form[keys.form.booleanBrandModel]"
                    :true-value="true"
                    :false-value="false"
                    inset
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="4"
                  v-for="(item, i) in form[keys.form.brandModel]"
                  :key="i"
                  class="pa-2"
                >
                  <v-card height="235" class="elevation-1">
                    <v-card-title class="subtitle-2">
                      {{ item[keys.brandModel.brandDescription].slice(0, 16)
                      }}<span
                        v-if="
                          item[keys.brandModel.brandDescription].length > 16
                        "
                        >...</span
                      >
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="overflow-y: auto; height: 145px">
                      <v-chip-group column>
                        <v-chip
                          small
                          outlined
                          label
                          v-for="(tag, i) in item[keys.brandModel.modelsArray]"
                          :key="i"
                        >
                          {{ tag[keys.brandModel.modelDescription] }}
                          <v-icon
                            small
                            color="primary"
                            right
                            v-if="tag[keys.brandModel.modelBooleanDeductible]"
                            >mdi-label</v-icon
                          >
                          <v-icon small right v-else
                            >mdi-label-off-outline</v-icon
                          >
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                    <v-card-actions class="py-0">
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        fab
                        color="error"
                        x-small
                        @click="handlerDeleteBrand(item)"
                        ><v-icon small>mdi-trash-can</v-icon></v-btn
                      >
                      <v-btn
                        text
                        fab
                        color="primary"
                        x-small
                        @click="handlerEditBrand(item)"
                        ><v-icon small>mdi-pencil</v-icon></v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4" class="pa-2">
                  <v-hover
                    v-slot="{ hover }"
                    :disabled="!form[keys.form.booleanBrandModel]"
                  >
                    <v-sheet
                      color="white"
                      :style="{ cursor: hover ? 'pointer' : '' }"
                      :elevation="hover ? 3 : 1"
                      height="235"
                      @click="
                        form[keys.form.booleanBrandModel]
                          ? (bmDialog.show = true)
                          : () => null
                      "
                      class="justify-center d-flex align-center"
                    >
                      <v-icon
                        x-large
                        :color="
                          form[keys.form.booleanBrandModel] ? 'primary' : 'grey'
                        "
                        >mdi-plus</v-icon
                      >
                    </v-sheet>
                  </v-hover>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- SECCION DE MATERIA ASEGURADA -->
          <v-expansion-panel class="no-elevation">
            <v-expansion-panel-header :class="panelClasses">
              Materia asegurada
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mt-3 py-3 align-center" no-gutters>
                <v-col cols="12" md="6">
                  <h4>¿Aplica las materia asegurada?</h4>
                  <p class="caption mb-0">
                    Habilitar las materias aseguradas para el canal.
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    v-model="form[keys.form.booleanInsuranceMateria]"
                    :true-value="true"
                    :false-value="false"
                    inset
                  ></v-switch>
                </v-col>
              </v-row>

              <v-autocomplete
                outlined
                chips
                small-chips
                deletable-chips
                label="Materias aseguradas"
                append-icon="mdi-select-all"
                @click:append="
                  selectAll({
                    form: keys.form.insuranceMateria,
                    get: keys.insuranceMateria.key,
                  })
                "
                v-model="form[keys.form.insuranceMateria]"
                :items="GetAdvanceConfig[keys.insuranceMateria.key]"
                :disabled="!form[keys.form.booleanInsuranceMateria]"
                :item-text="keys.insuranceMateria.description"
                return-object
                clearable
                multiple
              >
              </v-autocomplete>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- SECCION DE USO DE VEHICULOS -->
          <v-expansion-panel class="no-elevation">
            <v-expansion-panel-header :class="panelClasses">
              Uso del producto
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mt-3 py-3 align-center" no-gutters>
                <v-col cols="12" md="6">
                  <h4>¿Aplica el uso del producto?</h4>
                  <p class="caption mb-0">
                    Habilitar el uso de productos para el canal.
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    v-model="form[keys.form.booleanProductUse]"
                    :true-value="true"
                    :false-value="false"
                    inset
                  ></v-switch>
                </v-col>
              </v-row>

              <v-autocomplete
                outlined
                chips
                small-chips
                deletable-chips
                label="Uso de productos"
                append-icon="mdi-select-all"
                @click:append="
                  selectAll({
                    form: keys.form.productUse,
                    get: keys.productUse.key,
                    disabled: keys.productUse.disabled,
                  })
                "
                v-model="form[keys.form.productUse]"
                :items="GetAdvanceConfig[keys.productUse.key]"
                :disabled="!form[keys.form.booleanProductUse]"
                :item-text="keys.productUse.description"
                :item-disabled="keys.productUse.disabled"
                return-object
                clearable
                multiple
              >
              </v-autocomplete>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- SECCION DE ANIOS -->
          <v-expansion-panel class="no-elevation">
            <v-expansion-panel-header :class="panelClasses">
              Años
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mt-3 py-3 align-center" no-gutters>
                <v-col cols="12" md="6">
                  <h4>¿Aplica el uso de años?</h4>
                  <p class="caption mb-0">
                    Habilitar el uso de años para el canal.
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    v-model="form[keys.form.booleanYear]"
                    :true-value="true"
                    :false-value="false"
                    inset
                  ></v-switch>
                </v-col>
              </v-row>

              <v-autocomplete
                outlined
                chips
                small-chips
                deletable-chips
                label="Listado de años"
                append-icon="mdi-select-all"
                @click:append="
                  selectAll({
                    form: keys.form.year,
                    get: keys.year.key,
                    value: keys.year.description,
                  })
                "
                v-model="form[keys.form.year]"
                :items="GetAdvanceConfig[keys.year.key]"
                :disabled="!form[keys.form.booleanYear]"
                :item-text="keys.year.description"
                :item-value="keys.year.description"
                clearable
                multiple
              >
              </v-autocomplete>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- SECCION DE ESTADO DE VEHICULO -->
          <v-expansion-panel class="no-elevation">
            <v-expansion-panel-header :class="panelClasses">
              Estado del vehículo
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mt-3 py-3 align-center" no-gutters>
                <v-col cols="12" md="6">
                  <h4>¿Aplica el estado de vehículo?</h4>
                  <p class="caption mb-0">
                    Habilitar el uso de estados de vehiculos en el canal.
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    v-model="form[keys.form.booleanVehicleStatus]"
                    :true-value="true"
                    :false-value="false"
                    inset
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row class="mt-0 pt-0">
                <!-- CHECKBOX NUEVO -->
                <v-col cols="12" md="6">
                  <v-checkbox
                    class="mt-0"
                    v-model="
                      form[keys.form.vehicleStatus][keys.form.vehicleNew]
                    "
                    label="Nuevo"
                    :true-value="true"
                    :false-value="false"
                    hide-details
                    :disabled="!form[keys.form.booleanVehicleStatus]"
                  ></v-checkbox>
                </v-col>

                <!-- CHECKBOX USADO -->
                <v-col cols="12" md="6">
                  <v-checkbox
                    class="mt-0"
                    v-model="
                      form[keys.form.vehicleStatus][keys.form.vehicleUsed]
                    "
                    label="Usado"
                    :true-value="true"
                    :false-value="false"
                    hide-details
                    :disabled="!form[keys.form.booleanVehicleStatus]"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- SECCION DE AVISO DE PRIVACIDAD -->
          <v-expansion-panel class="no-elevation">
            <v-expansion-panel-header :class="panelClasses">
              Aviso de privacidad
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mt-3 py-3 align-center" no-gutters>
                <v-col cols="12" md="6">
                  <h4>¿Aplica aviso de privacidad al canal?</h4>
                  <p class="caption mb-0">
                    Habilitar el uso de mensajes de privacidad para el canal.
                  </p>
                </v-col>
                <v-col cols="6" md="3">
                  <v-switch
                    v-model="form[keys.form.booleanPrivacy]"
                    :true-value="true"
                    :false-value="false"
                    inset
                  ></v-switch>
                </v-col>
                <v-col cols="6" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Vigencia"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="form[keys.form.privacyValidity]"
                      name="vigencia_policy"
                      label="Vigencia (días)"
                      type="number"
                      outlined
                      dense
                      :disabled="!form[keys.form.booleanPrivacy]"
                      :error-messages="errors"
                      placeholder="Especifique los dias de vigencia de la política."
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col
                  cols="12"
                  v-for="(i, index) in form[keys.form.privacy]"
                  :key="index"
                >
                  <v-alert
                    :color="
                      !form[keys.form.booleanPrivacy]
                        ? 'grey lighten-2'
                        : 'primary'
                    "
                    icon="mdi-information"
                    colored-border
                    border="left"
                    type="info"
                    elevation="2"
                  >
                    {{ i[keys.privacy.subtitle] }}
                    <a
                      @click="
                        (privacyDialog.selected = i),
                          (privacyDialog.example = true)
                      "
                      >{{ i[keys.privacy.title].toLowerCase() }}</a
                    >
                    <template #append>
                      <v-btn
                        color="primary"
                        fab
                        x-small
                        text
                        :disabled="!form[keys.form.booleanPrivacy]"
                        @click="
                          (privacyDialog.selected = { ...i, id: index }),
                            (privacyDialog.show = true)
                        "
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        color="error"
                        fab
                        x-small
                        text
                        :disabled="!form[keys.form.booleanPrivacy]"
                        @click="delPrivacy(index)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </template>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-hover
                    v-slot="{ hover }"
                    :disabled="!form[keys.form.booleanPrivacy]"
                  >
                    <v-sheet
                      color="white"
                      :style="{ cursor: hover ? 'pointer' : '' }"
                      :elevation="hover ? 3 : 1"
                      height="55"
                      @click="
                        form[keys.form.booleanPrivacy]
                          ? (privacyDialog.show = true)
                          : () => null
                      "
                      class="justify-center d-flex align-center"
                    >
                      <v-icon
                        :color="
                          form[keys.form.booleanPrivacy] ? 'primary' : 'grey'
                        "
                        >mdi-plus</v-icon
                      >
                    </v-sheet>
                  </v-hover>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
    </validation-observer>

    <!-- MODAL DE SELECCION DE MODELOS Y MARCAS -->
    <v-dialog v-model="bmDialog.show" persistent max-width="500px" scrollable>
      <v-form ref="bmForm">
        <v-card>
          <v-card-title primary-title>
            Seleccione una marca y sus modelos
          </v-card-title>
          <v-divider class="mb-6"></v-divider>
          <v-card-text>
            <!-- SELECCIONAR MARCAS -->
            <v-autocomplete
              outlined
              dense
              :disabled="bmDialog.selected"
              label="Listado de marcas"
              v-model="bmDialog.brandSelected"
              :items="GetAdvanceConfig[keys.brandModel.key]"
              :item-text="keys.brandModel.brandDescription"
              :item-disabled="keys.brandModel.brandDisabled"
              @change="handlerModalSelectBrand()"
              return-object
              :rules="[(v) => !!v || 'Seleccione una marca']"
            >
            </v-autocomplete>
            <!-- SELECCIONAR MODELOS -->
            <v-autocomplete
              outlined
              dense
              chips
              small-chips
              clearable
              deletable-chips
              :disabled="!bmDialog.brandSelected"
              label="Listado de modelos"
              hint="Seleccione los modelos de la marca que desea utilizar para el canal."
              persistent-hint
              v-model="bmDialog.modelSelected"
              :items="
                bmDialog.brandSelected?.[keys.brandModel.modelsArray] ?? []
              "
              append-icon="mdi-select-all"
              @click:append="selectAllModels()"
              return-object
              multiple
              :item-text="keys.brandModel.modelDescription"
              :item-disabled="keys.brandModel.modelDisabled"
            >
            </v-autocomplete>
          </v-card-text>

          <v-divider v-if="bmDialog.modelSelected.length > 0"></v-divider>

          <!-- SELECCIONAR DEDUCIBLES DE UN MODELO -->
          <v-card-text>
            <div
              v-for="(model, mIndex) in bmDialog.modelSelected"
              :key="model[keys.brandModel.id]"
            >
              <v-divider v-if="mIndex !== 0" class="my-3"></v-divider>
              <h4 class="label">
                {{ model[keys.brandModel.modelDescription] }}
              </h4>

              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-checkbox
                    dense
                    label="¿Aplicar los deducibles?"
                    v-model="model[keys.brandModel.modelBooleanDeductible]"
                    :true-value="true"
                    :false-value="false"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    x-small
                    text
                    fab
                    :disabled="
                      !model[keys.brandModel.modelBooleanDeductible] ||
                      !model?.[keys.brandModel.modelDeductible]?.length > 0
                    "
                    @click="selectAllDeductible(model)"
                  >
                    <v-icon>mdi-select-all</v-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    text
                    fab
                    :disabled="
                      !model[keys.brandModel.modelBooleanDeductible] ||
                      !model?.[keys.brandModel.modelDeductible]?.length > 0
                    "
                    @click="selectAllDeductible(model, 'r')"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-chip-group
                v-model="model[keys.brandModel.modelDeductible]"
                column
                multiple
              >
                <v-chip
                  v-for="(chip, chipIndex) in GetAdvanceConfig[
                    keys.deductible.key
                  ]"
                  :key="chipIndex"
                  filter
                  outlined
                  small
                  :disabled="!model[keys.brandModel.modelBooleanDeductible]"
                  :value="`${chip[keys.deductible.description]}`"
                >
                  {{ chip[keys.deductible.description] }}
                </v-chip>
              </v-chip-group>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeModalBM()">Cerrar</v-btn>
            <v-btn color="primary" @click="handlerSelectBrands()"
              >Aplicar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <privacy-form
      ref="privacyForm"
      v-model="privacyDialog.show"
      :loading="privacyDialog.loading"
      :form-data="privacyDialog.selected"
      @close="closePrivacyForm"
      @submit="submitPrivacyForm"
    />
    <privacy-modal
      v-model="privacyDialog.example"
      @close="privacyDialog.example = false"
      :title="privacyDialog.selected?.[keys.privacy.title]"
      :policy="privacyDialog.selected?.[keys.privacy.message]"
    />
  </div>
</template>

<script>
import { advanceConfigKeys } from "@/store/resources/channelkeys.js";
import { mapActions, mapGetters } from "vuex";

import privacyForm from "@/components/Forms/advanceConfig/privacy.vue";
import privacyModal from "@/components/Forms/advanceConfig/privacyShowModal.vue";
export default {
  name: "AdvanceConfigForm",
  props: {
    panelClasses: {
      type: String,
      default: "",
    },
    channel: {
      type: String,
      default: "",
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    privacyForm,
    privacyModal,
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (!this.loading) {
          this.$emit("change");
        }
      },
    },
  },
  data: () => ({
    panels: [0, 1, 2, 3, 4, 5],
    loading: false,
    form: {
      [advanceConfigKeys.form.booleanBrandModel]: false,
      [advanceConfigKeys.form.brandModel]: [],

      [advanceConfigKeys.form.booleanInsuranceMateria]: false,
      [advanceConfigKeys.form.insuranceMateria]: [],

      [advanceConfigKeys.form.booleanProductUse]: false,
      [advanceConfigKeys.form.productUse]: [],

      // [advanceConfigKeys.form.booleanZeroKm]: false,
      // [advanceConfigKeys.form.zeroKm]: [],

      [advanceConfigKeys.form.booleanYear]: false,
      [advanceConfigKeys.form.year]: [],

      [advanceConfigKeys.form.booleanVehicleStatus]: false,
      [advanceConfigKeys.form.vehicleStatus]: {
        [advanceConfigKeys.form.vehicleNew]: false,
        [advanceConfigKeys.form.vehicleUsed]: false,
      },

      [advanceConfigKeys.form.booleanPrivacy]: false,
      [advanceConfigKeys.form.privacyValidity]: 1,
      [advanceConfigKeys.form.privacy]: [],
    },
    bmDialog: {
      show: false,
      selected: null,
      brandSelected: null,
      modelSelected: [],
    },
    privacyDialog: {
      show: false,
      loading: false,
      selected: null,
      example: false,
    },
  }),
  created() {
    this.setup();
  },
  computed: {
    ...mapGetters("Channels", ["GetAdvanceConfig"]),

    keys() {
      return advanceConfigKeys;
    },
    policyUnsaved() {
      return this.form[this.keys.form.privacy].some((obj) => !("_id" in obj));
    },
  },
  methods: {
    ...mapActions("Channels", [
      "REQUEST_CHANNEL_ADVANCE_CONFIG",
      "REQUEST_CHANNEL_ADVANCE_CONFIG_DATA",
    ]),

    setup() {
      this.loading = true;
      this.resetData();
      this.REQUEST_CHANNEL_ADVANCE_CONFIG()
        .then(async () => {
          const data = await this.REQUEST_CHANNEL_ADVANCE_CONFIG_DATA(
            this.channel
          );
          this.setupData(data);
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
    setupData(data) {
      const formKeys = this.keys.form;
      for (let key in formKeys) {
        if (data[formKeys[key]]) {
          this.form[formKeys[key]] = data[formKeys[key]];
        }
      }
    },
    resetData() {
      const keys = this.keys.form;

      this.form[keys.booleanBrandModel] = false;
      this.form[keys.brandModel] = [];

      this.form[keys.booleanInsuranceMateria] = false;
      this.form[keys.insuranceMateria] = [];

      this.form[keys.booleanProductUse] = false;
      this.form[keys.productUse] = [];

      this.form[keys.booleanYear] = false;
      this.form[keys.year] = [];

      this.form[keys.booleanVehicleStatus] = false;

      this.form[keys.vehicleStatus][keys.vehicleNew] = false;
      this.form[keys.vehicleStatus][keys.vehicleUsed] = false;

      this.form[keys.booleanPrivacy] = false;
      this.form[keys.privacyValidity] = 1;
      this.form[keys.privacy] = [];
    },

    handlerModalSelectBrand() {
      this.bmDialog.modelSelected = [];
    },

    // Seleccionar las marcas y los modelos desde una modal
    handlerSelectBrands() {
      const isValid = this.$refs.bmForm.validate();
      if (isValid) {
        // Se verifica que no exista la marca seleccionada en el form.
        const checkBrand = this.form[this.keys.form.brandModel]?.find(
          (e) =>
            e[this.keys.brandModel.brandId] &&
            (e[this.keys.brandModel.brandId] ==
              this.bmDialog.brandSelected[this.keys.brandModel.brandId] ||
              e[this.keys.brandModel.brandId] ==
                this.bmDialog.brandSelected[this.keys.brandModel.id])
        );
        // Si existe y no es modo editar
        if (checkBrand && !this.bmDialog.selected) {
          this.$store.commit("setSnackbar", {
            active: true,
            text: "La marca seleccionada ya existe en la configuración avanzada.",
            top: true,
            right: true,
            color: "warning",
          });
        } else {
          const brand = {
            [this.keys.brandModel.brandId]:
              this.bmDialog.brandSelected[this.keys.brandModel.brandId] ||
              this.bmDialog.brandSelected[this.keys.brandModel.id] ||
              "",
            [this.keys.brandModel.brandCode]:
              this.bmDialog.brandSelected[this.keys.brandModel.brandCode] || "",
            [this.keys.brandModel.brandDescription]:
              this.bmDialog.brandSelected[
                this.keys.brandModel.brandDescription
              ] || "",
            [this.keys.brandModel.modelsArray]: [],
          };
          const models = this.bmDialog.modelSelected.map((e) => {
            let modelData = {
              [this.keys.brandModel.modelId]:
                e[this.keys.brandModel.modelId] ||
                e[this.keys.brandModel.id] ||
                "",
              [this.keys.brandModel.modelCode]:
                e[this.keys.brandModel.modelCode] || "",
              [this.keys.brandModel.modelDescription]:
                e[this.keys.brandModel.modelDescription] || "",
              [this.keys.brandModel.modelBooleanDeductible]:
                e[this.keys.brandModel.modelBooleanDeductible] ?? false,
              [this.keys.brandModel.modelDeductible]:
                e[this.keys.brandModel.modelDeductible] ?? [],
            };
            brand[this.keys.brandModel.modelsArray].push(modelData);
            return modelData;
          });

          if (this.bmDialog.selected) {
            // Editar los modelos del elemento existente
            this.bmDialog.brandSelected[this.keys.brandModel.modelsArray] =
              models;
          } else {
            // Guardar un nuevo elemento
            this.form[this.keys.form.brandModel].push(brand);
          }
          this.closeModalBM();
        }
      }
    },

    // Metodo para editar una marca del formulario
    handlerEditBrand(item) {
      this.bmDialog.selected = true;
      this.bmDialog.brandSelected = item;
      this.bmDialog.modelSelected =
        item[this.keys.brandModel.modelsArray] ?? [];
      this.bmDialog.show = true;
    },

    // Metodo para eliminar una marca del formulario
    handlerDeleteBrand(item) {
      this.form[this.keys.form.brandModel] = this.form[
        this.keys.form.brandModel
      ].filter(
        (e) =>
          !(
            e[this.keys.brandModel.brandId] ==
            item[this.keys.brandModel.brandId]
          )
      );
    },

    // Metodo para seleccionar todos los elementos
    selectAll(data = { form: "", get: "", disabled: "", value: "" }) {
      if (!data.disabled && !data.value) {
        this.form[data.form] = [...this.GetAdvanceConfig[data.get]];
        return true;
      }

      if (data.disabled) {
        this.form[data.form] = [];
        this.GetAdvanceConfig[data.get].forEach((element) => {
          if (!element[data.disabled]) {
            this.form[data.form].push(element);
          }
        });
      }

      if (data.value) {
        const selectedArray = data.disabled
          ? this.form[data.form]
          : this.GetAdvanceConfig[data.get];
        this.form[data.form] = selectedArray.map((element) => {
          return element[data.value];
        });
      }
    },

    selectAllModels() {
      // Seleccionar todas las marcas y modelos
      this.bmDialog.modelSelected = [
        ...this.bmDialog.brandSelected[this.keys.brandModel.modelsArray],
      ];
    },

    selectAllDeductible(item, action = "a") {
      // // Seleccionar todos los deducibles para un modelo
      item[this.keys.brandModel.modelDeductible] =
        action === "a"
          ? this.GetAdvanceConfig?.[this.keys.deductible.key]?.map(
              (e) => e[this.keys.deductible.description]
            ) ?? []
          : [];
    },

    closeModalBM() {
      this.bmDialog.selected = false;
      this.bmDialog.brandSelected = null;
      this.bmDialog.modelSelected = [];
      this.bmDialog.show = false;
    },
    async submitPrivacyForm(data) {
      if (this.privacyDialog.selected) {
        await this.editPrivacy(data);
      } else {
        await this.savePrivacy(data);
      }
      await this.closePrivacyForm();
    },
    async savePrivacy(formData) {
      const data = { ...formData };
      this.form[this.keys.form.privacy].push(data);
    },
    async editPrivacy(formData) {
      const { id, ...data } = formData;
      const selected = this.form[this.keys.form.privacy]?.[id];
      if (selected) {
        selected[this.keys.privacy.title] = data[this.keys.privacy.title];
        selected[this.keys.privacy.message] = data[this.keys.privacy.message];
        selected[this.keys.privacy.subtitle] = data[this.keys.privacy.subtitle];
      }
    },
    async delPrivacy(id) {
      const privacyArray = this.form[this.keys.form.privacy];
      if (id > -1 && id < privacyArray.length) {
        // Eliminamos el elemento en el índice especificado
        privacyArray.splice(id, 1);
      }
    },
    async closePrivacyForm() {
      this.$refs.privacyForm.reset();
      this.privacyDialog.show = false;
      this.privacyDialog.selected = null;
    },
  },
};
</script>

<style></style>

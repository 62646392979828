var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v("Usuarios módulo de ventas")]),_c('v-row',{staticClass:"my-6"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"name":"search","label":"Buscar usuarios","id":"id_search","outlined":"","append-icon":"mdi-magnify","disabled":_vm.loading},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right pt-6",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"primary","text":"","to":{ name: 'UserConfig' },"disabled":_vm.loading}},[_vm._v("Administrar Usuarios")])],1)],1),_c('h4',{staticClass:"grey--text text--darken-1"},[_vm._v("Resultados")]),_c('v-data-table',{attrs:{"headers":_vm.tableHeader,"items":_vm.GetUsers,"items-per-page":5,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:`item.${_vm.keys.position}`,fn:function({ item }){return [_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.GetUserPosition[item[_vm.keys.position]] || _vm.GetUserPosition["default"] || item[_vm.keys.position])+" ")])]}},{key:`item.${_vm.keys.state}`,fn:function({ item }){return [_c('span',{class:[
          _vm.GetUserState[item[_vm.keys.state]]
            ? `${_vm.GetUserState[item[_vm.keys.state]].color}--text`
            : `${_vm.GetUserState['default'].color}--text`,
          'text--darken-1',
        ]},[_c('v-icon',{attrs:{"small":"","color":_vm.GetUserState[item[_vm.keys.state]]
              ? _vm.GetUserState[item[_vm.keys.state]].color
              : _vm.GetUserState['default'].color}},[_vm._v("mdi-"+_vm._s(_vm.GetUserState[item[_vm.keys.state]] ? _vm.GetUserState[item[_vm.keys.state]].icon : _vm.GetUserState["default"].icon))]),_vm._v(" "+_vm._s(_vm.GetUserState[item[_vm.keys.state]] ? _vm.GetUserState[item[_vm.keys.state]].text || _vm.GetUserState["default"].text || item[_vm.keys.state] : _vm.GetUserState["default"].text || item[_vm.keys.state]))],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <h4>Usuarios módulo de ventas</h4>
    <v-row class="my-6">
      <v-col cols="12" md="6">
        <v-text-field
          name="search"
          label="Buscar usuarios"
          id="id_search"
          outlined
          append-icon="mdi-magnify"
          v-model="search"
          :disabled="loading"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="text-right pt-6">
        <v-btn
          color="primary"
          text
          :to="{ name: 'UserConfig' }"
          :disabled="loading"
          >Administrar Usuarios</v-btn
        >
      </v-col>
    </v-row>
    <h4 class="grey--text text--darken-1">Resultados</h4>
    <v-data-table
      :headers="tableHeader"
      :items="GetUsers"
      :items-per-page="5"
      :loading="loading"
      :search="search"
    >
      <template v-slot:[`item.${keys.position}`]="{ item }">
        <v-chip small>
          {{
            GetUserPosition[item[keys.position]] ||
            GetUserPosition["default"] ||
            item[keys.position]
          }}
        </v-chip>
      </template>
      <template v-slot:[`item.${keys.state}`]="{ item }">
        <span
          :class="[
            GetUserState[item[keys.state]]
              ? `${GetUserState[item[keys.state]].color}--text`
              : `${GetUserState['default'].color}--text`,
            'text--darken-1',
          ]"
        >
          <v-icon
            small
            :color="
              GetUserState[item[keys.state]]
                ? GetUserState[item[keys.state]].color
                : GetUserState['default'].color
            "
            >mdi-{{
              GetUserState[item[keys.state]]
                ? GetUserState[item[keys.state]].icon
                : GetUserState["default"].icon
            }}</v-icon
          >
          {{
            GetUserState[item[keys.state]]
              ? GetUserState[item[keys.state]].text ||
                GetUserState["default"].text ||
                item[keys.state]
              : GetUserState["default"].text || item[keys.state]
          }}</span
        >
        <!-- <v-switch
          color="primary"
          v-model="item.status"
          :false-value="false"
          hide-details
          :true-value="true"
          :input-value="item.status"
        ></v-switch>
        <span class="caption primary--text" v-if="item.status">Activo</span>
        <span class="caption grey--text" v-else>Inactivo</span> -->
      </template>

      <!-- VIEJO METODO PARA EDITAR USUARIO -->
      <!-- <template v-slot:[`item.${keys.actions}`]="{ item }">
        <v-tooltip color="primary" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              text
              fab
              v-bind="attrs"
              v-on="on"
              @click="handlerEditUser(item)"
              small
              :disabled="loading"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar a {{ item[GetUserKeys.name] }}</span>
        </v-tooltip>
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BusinessUserForm",
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    channel: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    search: "",
    loading: false,
    selectedUser: null,
    keys: {
      rut: "usuario",
      firstName: "nombre",
      lastName: "primer_apellido",
      position: "perfil",
      contact: "correo_electronico",
      state: "estado",
      // actions: "actions",
    },
    modal: false,
  }),
  created() {
    this.setup();
  },
  computed: {
    ...mapGetters("Channels", ["GetUsers"]),
    ...mapGetters("Users", ["GetUserKeys", "GetUserState", "GetUserPosition"]),

    tableHeader() {
      return [
        { text: "RUT", value: this.keys.rut },
        { text: "Nombre", value: this.keys.firstName },
        { text: "Apellido", value: this.keys.lastName },
        { text: "Cargo", value: this.keys.position },
        { text: "Contacto", value: this.keys.contact },
        { text: "Estado", value: this.keys.state },
        // { text: "", value: this.keys.actions },
      ];
    },
  },
  methods: {
    ...mapActions("Channels", [
      "REQUEST_CHANNEL_USERS",
      // "REQUEST_CHANNEL_DOMAIN",
    ]),

    setup() {
      this.loading = true;
      this.REQUEST_CHANNEL_USERS(this.channel)
        // .then(() => {
        //   this.REQUEST_CHANNEL_DOMAIN(this.channel);
        // })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>

<template>
  <validation-observer ref="observer">
    <v-form>
      <v-fade-transition>
        <v-overlay v-if="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-fade-transition>
      <v-expansion-panels v-model="panels" multiple>
        <!-- SECCION DE TIPOS DE FIRMA -->
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            Tipo de Firma
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="mt-3 py-3">
              <h4>Seleccionar el tipo de firma</h4>
              <p class="caption mb-0">
                Mostrar al cliente los tipos de firma disponibles para el canal
              </p>
            </div>
            <validation-provider
              v-slot="{ errors }"
              :name="`tipo de firma`"
              rules="required"
            >
              <v-row>
                <v-col
                  cols="4"
                  v-for="(item, i) in GetSignatureType"
                  :key="item[GetAdvanceInfoKeys.signature.id] || i"
                >
                  <v-checkbox
                    v-model="form[GetAdvanceInfoKeys.signature.key]"
                    :label="item[GetAdvanceInfoKeys.signature.description]"
                    :value="item"
                  ></v-checkbox>
                  <!-- Condiciones de deshabilitar firma digital -->
                  <!-- :disabled="
                  item[GetAdvanceInfoKeys.signature.code] == 'DIG' &&
                  form[GetAdvanceInfoKeys.signature.key].length > 0 &&
                  (form[GetAdvanceInfoKeys.signature.key].find(
                    (e) => e.codigo == item.codigo
                  )
                    ? true
                    : false)
                " -->
                </v-col>
              </v-row>
              <v-slide-y-transition>
                <p class="caption error--text mb-0" v-if="errors.length > 0">
                  {{ errors[0] }}
                </p>
              </v-slide-y-transition>
            </validation-provider>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- SECCION DE CUOTAS EN COTIZACIONES -->
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            Cantidad de cuotas a mostrar en cotizaciones
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="mt-3 py-3">
              <h4>Seleccionar las cuotas a mostrar en cotizaciones</h4>
            </div>
            <v-row>
              <v-col cols="4">
                <v-checkbox
                  v-model="
                    form[GetAdvanceInfoKeys.fees.key][
                      GetAdvanceInfoKeys.fees.productValidity
                    ]
                  "
                  label="Por vigencia del producto"
                  @change="handlerSelectProductValidity"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="
                    form[GetAdvanceInfoKeys.fees.key][
                      GetAdvanceInfoKeys.fees.fixedInstallments
                    ]
                  "
                  label="Número de cuotas fijas"
                  @change="handlerSelectFixedInstallments"
                ></v-checkbox>
                <v-text-field
                  class="pt-5"
                  v-if="
                    form[GetAdvanceInfoKeys.fees.key][
                      GetAdvanceInfoKeys.fees.fixedInstallments
                    ]
                  "
                  v-model="
                    form[GetAdvanceInfoKeys.fees.key][
                      GetAdvanceInfoKeys.fees.fees
                    ]
                  "
                  :rules="rules.feesRule"
                  type="Number"
                  label="Cuotas"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- SECCION DE DESCUENTOS -->
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            Descuentos
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row align="center" class="my-3">
              <v-col cols="6" md="6">
                <h4>¿Aplica descuentos?</h4>
                <p class="caption mb-0">
                  Habilitar/deshabilitar descuentos en la venta.
                </p>
              </v-col>
              <v-col cols="6" md="3">
                <v-row no-gutters justify="center" align="center">
                  <v-col v-if="!mobile">No</v-col>
                  <v-col cols="12" md="5">
                    <v-switch
                      v-model="form[GetAdvanceInfoKeys.discount.key]"
                      :true-value="true"
                      :false-value="false"
                      mandatory
                      inset
                      dense
                    ></v-switch>
                  </v-col>
                  <v-col v-if="!mobile">Si</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- SECCION DE INSPECCIONES -->
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            Inspecciones
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row align="center" class="my-3">
              <v-col cols="6" md="6">
                <h4>¿Aplica inspección?</h4>
                <p class="caption mb-0">Solicitar inspección al cliente</p>
              </v-col>
              <v-col cols="6" md="3">
                <v-row no-gutters justify="center" align="center">
                  <v-col v-if="!mobile">No</v-col>
                  <v-col cols="12" md="5">
                    <v-switch
                      v-model="form[GetAdvanceInfoKeys.inspections.needed]"
                      :true-value="true"
                      :false-value="false"
                      mandatory
                      inset
                      dense
                    ></v-switch>
                  </v-col>
                  <v-col v-if="!mobile">Si</v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row align="center" no-gutters class="mb-3">
              <v-col cols="6" md="6">
                <h4>¿Gestiona inspección Mclick?</h4>
              </v-col>
              <v-col cols="6" md="3">
                <v-row no-gutters justify="center" align="center">
                  <v-col v-if="!mobile">No</v-col>
                  <v-col cols="12" md="5">
                    <v-switch
                      v-model="form[GetAdvanceInfoKeys.inspections.manage]"
                      :true-value="true"
                      :false-value="false"
                      mandatory
                      inset
                      dense
                    ></v-switch>
                  </v-col>
                  <v-col v-if="!mobile">Si</v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-col
              v-if="form[GetAdvanceInfoKeys.inspections.needed]"
              class="pa-0"
            >
              <v-row
                v-for="(item, i) of form[GetAdvanceInfoKeys.inspections.list]"
                :key="i"
                :dense="mobile"
              >
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="`Empresa nro${i + 1}`"
                    rules="required"
                    :disabled="!form[GetAdvanceInfoKeys.inspections.needed]"
                  >
                    <v-select
                      :items="companies"
                      v-model="item[GetAdvanceInfoKeys.inspections.business.id]"
                      label="Empresa"
                      outlined
                      :error-messages="errors"
                      :item-text="GetAdvanceInfoKeys.inspections.business.text"
                      :item-value="
                        GetAdvanceInfoKeys.inspections.business.value
                      "
                      :disabled="!form[GetAdvanceInfoKeys.inspections.needed]"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="`Tipo de inspección nro${i + 1}`"
                    rules="required"
                    :disabled="!form[GetAdvanceInfoKeys.inspections.needed]"
                  >
                    <v-select
                      :items="GetInspectionTypes"
                      v-model="item[GetAdvanceInfoKeys.inspections.type.id]"
                      label="Tipos de inspección"
                      :error-messages="errors"
                      outlined
                      multiple
                      :item-text="GetAdvanceInfoKeys.inspections.type.text"
                      :item-value="GetAdvanceInfoKeys.inspections.type.value"
                      :disabled="!form[GetAdvanceInfoKeys.inspections.needed]"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row align="center" no-gutters class="pa-0">
                <v-col cols="6" md="6">
                  <h4>¿Especifica por compañia?</h4>
                </v-col>
                <v-col cols="6" md="3">
                  <v-row no-gutters justify="center" align="center">
                    <v-col v-if="!mobile">No</v-col>
                    <v-col cols="12" md="5">
                      <v-switch
                        v-model="form[GetAdvanceInfoKeys.inspections.specific]"
                        :true-value="true"
                        :false-value="false"
                        mandatory
                        inset
                        dense
                        @change="handlerchangeSpecific"
                      ></v-switch>
                    </v-col>
                    <v-col v-if="!mobile">Si</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                v-if="form[GetAdvanceInfoKeys.inspections.specific]"
                no-gutters
              >
                <v-container wrap class="pa-2">
                  <div class="mt-3">
                    <v-row align="center">
                      <v-col
                        cols="6"
                        sm="4"
                        md="3"
                        v-for="(company, index) of selectCompanies"
                        :key="index"
                      >
                        <div
                          :class="{
                            'picture-box d-flex align-center justify-center': true,
                            selected: form[
                              GetAdvanceInfoKeys.inspections.specificList
                            ].find((e) => e.compania_id === company.id)
                              ? true
                              : false,
                          }"
                          @click="handlerSelectCompany(company)"
                          :title="company.descripcion"
                        >
                          <span>
                            <v-img :src="company.logo" max-width="100">
                              <template #placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="primary"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
                <v-container wrap class="pa-0">
                  <v-row no-gutters>
                    <v-col cols="12" class="pa-0">
                      <v-row
                        v-for="(company, index) of form[
                          GetAdvanceInfoKeys.inspections.specificList
                        ]"
                        :key="index"
                        no-gutters
                      >
                        <v-col cols="3" class="mt-1">
                          <p>{{ company.compania_descripcion }}</p>
                        </v-col>
                        <v-col cols="4" class="mr-5">
                          <v-select
                            :items="companies"
                            v-model="
                              company.empresas_inspecciones[0]
                                .empresa_inspeccion_id
                            "
                            label="Empresa"
                            outlined
                            :item-text="
                              GetAdvanceInfoKeys.inspections.business.text
                            "
                            :item-value="
                              GetAdvanceInfoKeys.inspections.business.value
                            "
                            @change="
                              (id) =>
                                handlerSelectEmpresaInspeccion(
                                  id,
                                  company.empresas_inspecciones[0]
                                )
                            "
                          ></v-select>
                        </v-col>

                        <v-col cols="4" class="ml-5">
                          <!--
                          <v-select
                            :items="GetInspectionTypes"
                            v-model="company.empresas_inspecciones[0].inspeccionesIds"
                            label="Tipos de inspección"
                            outlined
                            multiple
                            :item-text="
                              GetAdvanceInfoKeys.inspections.type.text
                            "
                            :item-value="
                              GetAdvanceInfoKeys.inspections.type.value
                            "
                            @change="(id)=>handlerSelectTipoInspeccion(company.empresas_inspecciones[0])"
                          ></v-select>
                          -->
                          <v-select
                            :items="GetInspectionTypes"
                            v-model="
                              company.empresas_inspecciones[0]
                                .inspeccionesIds[0]
                            "
                            label="Tipos de inspección"
                            outlined
                            :item-text="
                              GetAdvanceInfoKeys.inspections.type.text
                            "
                            :item-value="
                              GetAdvanceInfoKeys.inspections.type.value
                            "
                            @change="
                              (id) =>
                                handlerSelectTipoInspeccion(
                                  company.empresas_inspecciones[0]
                                )
                            "
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!--  SECCION DOCUMENTO DE VENTA -->
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            Documento de Venta
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row align="center" class="my-3">
              <v-col cols="8" md="6">
                <h4>Habilitar documento de Venta</h4>
                <p class="caption mb-0">
                  Solicitar al cliente un documento de venta o factura
                </p>
              </v-col>
              <v-col cols="4" md="3">
                <v-row no-gutters justify="center" align="center">
                  <v-col v-if="!mobile">No</v-col>
                  <v-col cols="12" md="5">
                    <v-switch
                      v-model="form[GetAdvanceInfoKeys.documents.key]"
                      :true-value="true"
                      :false-value="false"
                      inset
                      dense
                    ></v-switch>
                  </v-col>
                  <v-col v-if="!mobile">Si</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!--  SECCION POLIZA MCLICK -->
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            Póliza Mclick
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row align="center" class="my-3">
              <v-col cols="8" md="6">
                <h4>Genera póliza Mclick</h4>
              </v-col>
              <v-col cols="4" md="3">
                <v-row no-gutters justify="center" align="center">
                  <v-col v-if="!mobile">No</v-col>
                  <v-col cols="12" md="5">
                    <v-switch
                      v-model="form[GetAdvanceInfoKeys.policy.key]"
                      :true-value="true"
                      :false-value="false"
                      inset
                      dense
                    ></v-switch>
                  </v-col>
                  <v-col v-if="!mobile">Si</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!--  SECCION POLIZA MCLICK -->
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            Generar informe de simulaciones
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row align="center" class="my-3">
              <v-col cols="8" md="6">
                <h4>Usuario genera informe de simulaciones</h4>
              </v-col>
              <v-col cols="4" md="3">
                <v-row no-gutters justify="center" align="center">
                  <v-col v-if="!mobile">No</v-col>
                  <v-col cols="12" md="5">
                    <v-switch
                      v-model="form[GetAdvanceInfoKeys.report.key]"
                      :true-value="true"
                      :false-value="false"
                      inset
                      dense
                    ></v-switch>
                  </v-col>
                  <v-col v-if="!mobile">Si</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { advanceInfoKeys } from "@/store/resources/channelkeys.js";
export default {
  name: "AdvanceInfoForm",
  props: {
    panelClasses: {
      type: String,
      default: "",
    },
    channel: {
      type: String,
      default: "",
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (!this.loading) {
          this.$emit("change");
        }
      },
    },
  },
  data: () => ({
    panels: [0, 1, 2, 3, 4, 5, 6],
    loading: false,
    selectCompaniesId: [],
    form: {
      [advanceInfoKeys.inspections.needed]: true,
      [advanceInfoKeys.inspections.specific]: false,
      [advanceInfoKeys.inspections.manage]: false,
      [advanceInfoKeys.inspections.specificList]: [],
      [advanceInfoKeys.inspections.list]: [],
      [advanceInfoKeys.signature.key]: [],
      [advanceInfoKeys.documents.key]: true,
      [advanceInfoKeys.policy.key]: false,
      [advanceInfoKeys.report.key]: false,
      [advanceInfoKeys.discount.key]: false,
      [advanceInfoKeys.fees.key]: {
        [advanceInfoKeys.fees.fees]: 0,
        [advanceInfoKeys.fees.productValidity]: true,
        [advanceInfoKeys.fees.fixedInstallments]: false,
      },
    },
    rules: {
      feesRule: [(v) => v > 0 || "Las cuotas deben ser mayores a 0"],
    },
    companies: [],
    selectCompanies: [],
    channelType: "",
  }),
  created() {
    this.setup();
  },
  computed: {
    ...mapGetters("Channels", [
      "GetCompanies",
      "GetSignatureType",
      "GetInspectionBusiness",
      "GetInspectionTypes",
      "GetAdvanceInfoKeys",
      "GetProductKeys",
    ]),
  },
  methods: {
    ...mapActions("Channels", [
      "REQUEST_CHANNEL_ADVANCE_INFO",
      "REQUEST_CHANNEL_COMPANIES",
      "REQUEST_CHANNEL_ADVANCE_INFO_DATA",
      "REQUEST_CHANNEL_PRODUCTS_DATA",
    ]),

    async setup() {
      this.loading = true;
      this.resetData();
      await this.REQUEST_CHANNEL_COMPANIES(this.channel);
      const data = this.GetCompanies;
      this.selectCompanies = data;
      this.REQUEST_CHANNEL_ADVANCE_INFO()
        .then(async () => {
          await this.setCompanies();
          const { data } = await this.REQUEST_CHANNEL_ADVANCE_INFO_DATA(
            this.channel
          );
          this.setupData(data.data.result);
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
    resetData() {
      const keys = this.GetAdvanceInfoKeys;

      this.form[keys.inspections.needed] = true;
      this.form[keys.inspections.specific] = false;
      this.form[keys.inspections.manage] = false;
      this.form[keys.inspections.specificList] = [];
      this.form[keys.inspections.list] = [];
      this.form[keys.signature.key] = [];
      this.form[keys.documents.key] = true;
      this.form[keys.policy.key] = false;
      this.form[keys.report.key] = false;
      this.form[keys.discount.key] = false;
      this.form[keys.fees.key][keys.fees.fees] = 0;
      this.form[keys.fees.key][keys.fees.productValidity] = true;
      this.form[keys.fees.key][keys.fees.fixedInstallments] = false;
    },
    setupData(data) {
      const keys = this.GetAdvanceInfoKeys;
      // Establecer la informacion de los tipos de firma
      this.form[keys.signature.key] = data[keys.signature.key];
      // Establecer la informacion de las cuotas en cotizaciones
      this.form[keys.fees.key][keys.fees.productValidity] =
        data[keys.fees.key]?.[keys.fees.productValidity];
      this.form[keys.fees.key][keys.fees.fixedInstallments] =
        data[keys.fees.key]?.[keys.fees.fixedInstallments];
      this.form[keys.fees.key][keys.fees.fees] =
        data[keys.fees.key]?.[keys.fees.fees];
      // Establecer la informacion de las inspecciones
      this.form[keys.inspections.needed] =
        data[keys.inspections.needed] ?? false;
      this.form[keys.inspections.list] = data[keys.inspections.list];
      this.form[keys.inspections.manage] =
        data[keys.inspections.manage] ?? false;

      // Establecer specific
      this.form[keys.inspections.specific] =
        data[keys.inspections.specific] ?? false;
      this.form[keys.inspections.specificList] =
        data[keys.inspections.specificList] ?? [];

      this.form[keys.inspections.specificList].forEach((element) => {
        element.empresas_inspecciones[0].inspeccionesIds =
          element.empresas_inspecciones[0].inspecciones.map(
            (e) => e.inspeccion_id
          );
      });

      // Establecer el estado de documento de venta
      this.form[keys.documents.key] = data[keys.documents.key] ?? false;
      // Establecer el estado de generar la póliza mclick
      this.form[keys.policy.key] = data[keys.policy.key] ?? false;
      // Establecer el estado de generar informe de simulaciones
      this.form[keys.report.key] = data[keys.report.key] ?? false;
      // Establecer el tipo de canal
      this.channelType =
        data[keys.channelType.getKey]?.[keys.channelType.code] ?? "";

      if (this.form[keys.inspections.list].length <= 0) {
        this.addField();
      }

      //Establecer Descuento
      this.form[keys.discount.key] = data[keys.discount.key] ?? false;
    },
    setCompanies() {
      this.companies = this.GetInspectionBusiness;
    },
    addField() {
      let item = {};
      item[this.GetAdvanceInfoKeys.inspections.business.id] = "";
      item[this.GetAdvanceInfoKeys.inspections.type.id] = [];
      this.form[this.GetAdvanceInfoKeys.inspections.list].push(item);
    },

    handlerSelectCompany(item) {
      const index = this.form[
        this.GetAdvanceInfoKeys.inspections.specificList
      ].findIndex((e) => e.compania_id == item.id);
      if (index > -1) {
        this.form[this.GetAdvanceInfoKeys.inspections.specificList].splice(
          index,
          1
        );
      } else {
        this.form[this.GetAdvanceInfoKeys.inspections.specificList].push({
          compania_id: item.id,
          compania_descripcion: item.descripcion,
          empresas_inspecciones: [
            {
              empresa_inspeccion_id: "",
              empresa_inspeccion_descripcion: "",
              inspeccionesIds: [],
              inspecciones: [],
            },
          ],
        });
      }
    },

    handlerSelectEmpresaInspeccion(id, empresa_inspeccion) {
      const company = this.companies.find((company) => company._id === id);
      empresa_inspeccion.empresa_inspeccion_descripcion = company.empresa;
    },

    handlerSelectTipoInspeccion(empresa_inspeccion) {
      const inspectionTypes = this.GetInspectionTypes;
      empresa_inspeccion.inspecciones = empresa_inspeccion.inspeccionesIds.map(
        (inspeccionId) => {
          return {
            inspeccion_id: inspeccionId,
            inspeccion_descripcion: inspectionTypes.find(
              (inspeccion) => inspeccion._id === inspeccionId
            ).inspeccion,
          };
        }
      );
    },

    handlerSelectProductValidity(e) {
      if (e) {
        this.form[this.GetAdvanceInfoKeys.fees.key][
          this.GetAdvanceInfoKeys.fees.fixedInstallments
        ] = false;
      }
    },

    handlerSelectFixedInstallments(e) {
      if (e) {
        this.form[this.GetAdvanceInfoKeys.fees.key][
          this.GetAdvanceInfoKeys.fees.productValidity
        ] = false;
      }
    },

    handlerchangeSpecific(value) {
      if (!value) {
        this.form[this.GetAdvanceInfoKeys.inspections.specificList] = [];
      }
    },
  },
};
</script>

<style scoped>
/* LOGO BOXES */
.picture-box {
  border: 2px solid #b4ccfc;
  height: 90px;
  overflow: hidden;
  border-bottom-width: 10px;
  cursor: pointer;
}
.picture-box:active {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) inset;
}
.picture-box.selected {
  border-color: #407df6;
}
.picture-box:hover {
  border-color: #88acf5;
}
.picture-box,
.picture-box.selected,
.picture-box:hover {
  transition: border-color 0.2s;
}
</style>

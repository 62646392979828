<template>
  <v-expansion-panels v-model="panels" multiple>
    <!-- SECCION DE PRODUCTOS COMPLEMENTARIOS -->
    <v-expansion-panel class="no-elevation">
      <v-expansion-panel-header :class="panelClasses">
        Productos complementarios
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-col cols="12" md="7" class="mt-6">
          <v-autocomplete
            :items="complementaryProducts"
            v-model="form.complementary"
            outlined
            multiple
            label="Selecciona entre los productos disponibles"
          ></v-autocomplete>
        </v-col>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!-- SECCION DE PRODUCTOS ADICIONALES -->
    <v-expansion-panel class="no-elevation">
      <v-expansion-panel-header :class="panelClasses">
        Productos adicionales
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-col cols="12" md="7" class="mt-6">
          <v-autocomplete
            :items="aditionalProducts"
            v-model="form.aditional"
            outlined
            multiple
            label="Selecciona entre los productos disponibles"
          ></v-autocomplete>
        </v-col>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "AdicionalProductForm",
  props: {
    panelClasses: {
      type: String,
      default: "",
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    panels: [0, 1],
    form: {
      aditional: [],
      complementary: [],
    },
  }),
  computed: {
    complementaryProducts() {
      return [
        {
          text: "Producto A",
          value: "a",
        },
        {
          text: "Producto B",
          value: "b",
        },
        {
          text: "Producto C",
          value: "c",
        },
        {
          text: "Producto D",
          value: "d",
        },
        {
          text: "Producto E",
          value: "e",
        },
      ];
    },
    aditionalProducts() {
      return this.complementaryProducts;
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <v-fade-transition mode="out-in">
      <v-overlay v-if="loading" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
    <v-row no-gutters>
      <v-col cols="12" md="5" lg="4" xl="3">
        <h4>Compañías seleccionadas</h4>
      </v-col>
      <v-col cols="12" :class="{ 'pa-3': mobile }">
        <!-- COMPANIES CAROUSEL -->
        <v-sheet
          class="mx-auto mt-3"
          :max-width="$vuetify.breakpoint.xl ? 890 : 700"
        >
          <v-slide-group
            show-arrows="always"
            v-model="model"
            multiple
            ref="slideGroup"
            @change="findProductsByCompanyEvent"
          >
            <v-slide-item
              v-for="company of GetCompanies"
              :value="company[GetProductKeys.company.id]"
              :key="`key_${company[GetProductKeys.company.id]}`"
              v-slot="{ active, toggle }"
            >
              <v-img
                contain
                :src="company[GetProductKeys.company.logo]"
                max-width="140"
                min-width="120"
                max-height="110"
                :alt="company[GetProductKeys.company.name]"
                :title="company[GetProductKeys.company.name]"
                @click="toggle"
                class="selectable hoverable mx-2 align-end"
              >
                <div class="text-right">
                  <v-scale-transition>
                    <v-btn
                      color="success lighten-1 elevation-0"
                      fab
                      v-if="active"
                      x-small
                    >
                      <v-icon class="text-h4">mdi-check-circle</v-icon>
                    </v-btn>
                  </v-scale-transition>
                </div>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>

        <v-divider class="my-3"></v-divider>
      </v-col>

      <v-col cols="12" md="5" class="py-6">
        <!-- BUSCADOR DE PRODUCTOS -->
        <v-text-field
          name="search_product"
          label="Buscar productos"
          append-icon="mdi-magnify"
          v-model="search"
          outlined
          dense
          hide-details
          id="id_search_product"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="7">
        <!-- INFO -->
        <p class="mb-0 caption text-right">
          <v-icon small color="warning">mdi-lan</v-icon> Heredable <br />
          <v-icon small color="success">mdi-cash-check</v-icon> Tarificable
          <br />
          <v-icon small color="purple">mdi-eye</v-icon> Visible <br />
        </p>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="12" class="secondary rounded pa-4 relative">
        <h3 class="grey--text text--darken-1">Asignar al canal</h3>
        <validation-observer ref="observer">
          <validation-provider
            v-slot="{ errors }"
            name="Productos"
            rules="required"
          >
            <input v-show="false" type="text" v-model="form.products" />
            <p
              v-if="errors.length > 0 && !(form.products.length > 0)"
              class="error--text caption"
            >
              Debe seleccionar al menos 1 plan.
            </p>
          </validation-provider>
        </validation-observer>
        <!-- VENTANA DE CARGA PRODUCTOS -->
        <v-fade-transition mode="out-in">
          <v-overlay v-if="loadingProducts" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-fade-transition>
        <v-fade-transition mode="out-in">
          <!-- PRODUCTS LIST -->
          <v-treeview
            v-if="GetProducts.length > 0"
            :items="GetProducts"
            selectable
            dense
            selected-color="primary"
            item-disabled="locked"
            :search="search"
            v-model="form.products"
            return-object
            :item-text="GetProductKeys.name"
            :item-children="GetProductKeys.items"
            :item-key="GetProductKeys.id"
            expand-icon="mdi-chevron-double-down"
          >
            <template #label="{ item, leaf }">
              <span v-if="leaf">
                {{ item.name }}
                <v-chip outlined label x-small color="primary">{{
                  `${item.vigencia_descripcion ?? ""} ${
                    item.vigencia_meses ?? ""
                  } ${item.vigencia_meses == 1 ? "MES" : "MESES"}`
                }}</v-chip>
              </span>
              <span v-else>
                {{ item.name }}
              </span>
            </template>
            <template #append="{ item, leaf, selected }">
              <span v-if="leaf">
                <v-btn-toggle
                  dense
                  background-color="rgba(0,0,0,0)"
                  multiple
                  :value="[
                    findFormProduct(item)?.[GetProductKeys.key1]
                      ? GetProductKeys.key1
                      : '',
                    findFormProduct(item)?.[GetProductKeys.key2]
                      ? GetProductKeys.key2
                      : '',
                    findFormProduct(item)?.[GetProductKeys.key3]
                      ? GetProductKeys.key3
                      : '',
                  ]"
                >
                  <v-btn
                    x-small
                    :disabled="!selected"
                    outlined
                    color="orange"
                    :value="GetProductKeys.key1"
                    @click="handlerChangeBooleanItem(item, GetProductKeys.key1)"
                  >
                    <v-icon small>mdi-lan</v-icon>
                  </v-btn>

                  <v-btn
                    x-small
                    :disabled="!selected"
                    outlined
                    color="green"
                    :value="GetProductKeys.key2"
                    @click="handlerChangeBooleanItem(item, GetProductKeys.key2)"
                  >
                    <v-icon small>mdi-cash-check</v-icon>
                  </v-btn>

                  <v-btn
                    x-small
                    :disabled="
                      !selected ||
                      (!form.products.find((e) => e._id === item._id)?.[
                        GetProductKeys.key2
                      ] ??
                        true)
                    "
                    outlined
                    color="purple"
                    :value="GetProductKeys.key3"
                    @click="handlerChangeBooleanItem(item, GetProductKeys.key3)"
                  >
                    <v-icon small>mdi-eye</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </span>
            </template>
          </v-treeview>
          <p v-else class="grey--text text--darken-1">
            Por favor seleccione una o más Compañias...
          </p>
        </v-fade-transition>
      </v-col>
    </v-row>
    <base-modal v-model="modal" @close="modal = false">
      <section class="pa-5">
        <h4 class="mb-6">Configuración avanzada</h4>
        <v-select
          :items="filters"
          v-model="filter"
          label="Filtrar por"
          outlined
          append-icon="mdi-filter"
        ></v-select>

        <v-checkbox value="company2" label="Resultado 1"></v-checkbox>
        <v-checkbox value="company1" label="Resultado 2"></v-checkbox>
        <div class="text-center">
          <v-btn color="primary" outlined>Guardar</v-btn>
        </div>
      </section>
    </base-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
// import { validarPermisoTipoCanal } from "@/store/resources/validate.js";
export default {
  name: "BusinessProductForm",
  props: {
    panelClasses: {
      type: String,
      default: "",
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    channel: {
      type: String,
      default: "",
    },
  },
  components: {
    BaseModal: () => import("@/components/Modal/BaseModal.vue"),
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (!this.loading) {
          this.$emit("change");
        }
      },
    },
  },
  data: () => ({
    loading: false,
    loadingProducts: false,
    form: {
      products: [],
    },
    model: [],
    search: "",
    filter: "",
    modal: false,
    // channelType: "",
  }),
  created() {
    this.setup();
  },
  computed: {
    ...mapGetters("Channels", [
      "GetProducts",
      "GetCompanies",
      "GetProductKeys",
    ]),
    // Permisos por tipo de canal
    // channelTypePermission() {
    //   return validarPermisoTipoCanal(1, this.channelType);
    // },

    filters() {
      return [
        {
          text: "Compañia",
          value: "compania",
        },
        {
          text: "Producto",
          value: "producto",
        },
        {
          text: "Plan",
          value: "plan",
        },
        {
          text: "Medio de Pago",
          value: "medio-pago",
        },
      ];
    },
  },
  methods: {
    ...mapActions("Channels", [
      "REQUEST_CHANNEL_PRODUCTS",
      "REQUEST_CHANNEL_COMPANIES",
      "REQUEST_CHANNEL_PRODUCTS_DATA",
    ]),
    ...mapMutations("Channels", ["ResetProducts", "ResetCompanies"]),

    // PAGE METHODS
    async setup() {
      this.loading = true;
      // Restore component data (Products, Companies and channel data)
      await this.resetAll();
      // Requesting companies list
      this.REQUEST_CHANNEL_COMPANIES(this.channel);
      // Requesting channel products saved data
      const { data } = await this.REQUEST_CHANNEL_PRODUCTS_DATA(this.channel);
      // Conditions
      // this.channelType =
      //   data?.data?.[this.GetProductKeys.channelType.getKey]?.[
      //     this.GetProductKeys.channelType.code
      //   ] ?? "";
      // Set companies previous selected
      const companies = data?.data?.[this.GetProductKeys.getCompanies] ?? [];
      this.model = companies;
      // Find products with companies previusly selected
      this.findProducts(companies);
      // Set products data
      await this.setProducts(data.data.result);
      this.loading = false;
    },
    resetAll() {
      return new Promise((resolve) => {
        this.form.products = [];
        this.ResetProducts();
        this.ResetCompanies();
        resolve();
      });
    },

    // SETTERS
    setProducts(items) {
      if (items.length > 0) {
        this.form.products = items;
      }
    },

    findProductsByCompanyEvent(e) {
      if (!this.loading) {
        this.findProducts(e);
      }
    },

    async findProducts(companies = []) {
      if (companies.length > 0) {
        this.loadingProducts = true;
        await this.REQUEST_CHANNEL_PRODUCTS({ id: this.channel, companies })
          .then(() => this.$emit("change"))
          .catch((message) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: message,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => (this.loadingProducts = false));
      }
    },

    findFormProduct(item) {
      return this.form.products.find((e) => e._id === item._id);
    },

    handlerChangeBooleanItem(item, key) {
      const plan = this.findFormProduct(item);
      if (plan) {
        plan[key] = !plan[key];
        if (key === this.GetProductKeys.key2 && !plan[key]) {
          plan[this.GetProductKeys.key3] = false;
        }
      }
    },
    // handleSelectAllKey(val, key = "") {
    //   if (key) {
    //     this.form.products.forEach((e) => {
    //       if (key == this.GetProductKeys.key3 && val) {
    //         e[key] = e[this.GetProductKeys.key2] ? val : false;
    //       } else {
    //         e[key] = val;
    //       }
    //       if (key == this.GetProductKeys.key2 && !val) {
    //         e[this.GetProductKeys.key3] = false;
    //       }
    //     });
    //   }
    // },
  },
};
</script>

<style scoped>
.box-max {
  max-height: 450px;
  overflow-y: auto;
}
</style>

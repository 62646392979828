<template>
  <validation-observer ref="observer">
    <v-form>
      <v-fade-transition mode="out-in">
        <v-overlay v-if="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-fade-transition>

      <v-expansion-panels v-model="panels" multiple>
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            Nueva Regla
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="pricingForm">
              <v-row dense class="mt-6" multi-line>
                <!-- campo de compania -->
                <v-col cols="12" md="6">
                  <v-autocomplete
                    :label="company.label"
                    v-model="company.selected"
                    :loading="loading"
                    item-text="compania_descripcion"
                    no-data-text="No se han encontrado compañías"
                    return-object
                    :items="items"
                    outlined
                    dense
                    :rules="company.rules"
                  ></v-autocomplete>
                </v-col>
                <!-- campo de producto -->
                <v-col cols="12" md="6">
                  <v-autocomplete
                    :label="product.label"
                    v-model="product.selected"
                    item-text="producto_descripcion"
                    item-value="producto_codigo_interno"
                    no-data-text="No se han encontrado productos"
                    :items="company.selected?.productos ?? []"
                    outlined
                    dense
                    :rules="product.rules"
                  ></v-autocomplete>
                </v-col>
                <!-- campo tipo de regla -->
                <v-col cols="12">
                  <v-autocomplete
                    :label="types.label"
                    v-model="types.selected"
                    item-text="descripcion"
                    item-value="valor"
                    :items="ruleItems"
                    @change="setupRules($event)"
                    :rules="types.rules"
                  ></v-autocomplete>
                </v-col>
                <!-- CONDICIONALES -->
                <v-col
                  v-if="brandTypes.includes(ruleMode)"
                  cols="12"
                  :md="ruleMode === brandTypes[1] ? 5 : 12"
                >
                  <!-- MARCAS -->
                  <v-autocomplete
                    :multiple="!(ruleMode === brandTypes[1])"
                    v-model="brand.selected"
                    :label="brand.label"
                    outlined
                    dense
                    :items="brandItems"
                    item-text="marca_descripcion"
                    item-disabled="eliminado"
                    return-object
                    :rules="[
                      (v) =>
                        (ruleMode === brandTypes[1]
                          ? Array.isArray(v)
                            ? v.length > 0
                            : !!v
                          : v?.length > 0) ||
                        'Debe seleccionar al menos una marca.',
                    ]"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip label small v-if="index < 5" dense>
                        <span>{{ item.marca_descripcion }}</span>
                      </v-chip>
                      <span v-if="index === 5" class="grey--text text-caption">
                        (+{{ brand.selected.length - 5 }} otros)
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <template v-if="ruleMode === brandTypes[1]">
                  <v-col cols="12" md="5">
                    <!-- MODELS FIELD -->
                    <v-autocomplete
                      v-model="models.selected"
                      :label="models.label"
                      outlined
                      dense
                      :items="brand.selected?.modelos ?? []"
                      item-text="modelo_descripcion"
                      item-disabled="eliminado"
                      return-object
                      :rules="models.rules"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="1">
                    <v-btn
                      color="primary"
                      height="40"
                      @click="addModelsToList()"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-row
                      dense
                      v-for="(i, index) in models.selectedByBrand"
                      :key="index"
                    >
                      <v-col cols="12" md="4" class="text-right"
                        ><v-badge color="primary" :content="i.marca" left>
                          {{ i.marca_descripcion }}
                        </v-badge></v-col
                      >
                      <v-col cols="12" md="2" class="align-center text-center">
                        <v-icon>mdi-arrow-expand-horizontal</v-icon>
                      </v-col>
                      <v-col cols="12" md="4" class="text-left">
                        <v-badge color="success" :content="i.modelo">
                          {{ i.modelo_descripcion }}
                        </v-badge>
                      </v-col>
                      <v-col cols="12" md="2" class="text-left">
                        <v-btn
                          color="error"
                          x-small
                          fab
                          text
                          @click="removeFromModelsList(index)"
                          ><v-icon>mdi-trash-can</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-select
                      v-show="false"
                      v-model="models.selectedByBrand"
                      :rules="[(v) => v.length > 0]"
                      ref="brandModelField"
                    ></v-select>
                    <p
                      class="text-center caption error--text"
                      v-if="models.error"
                    >
                      {{ models.error }}
                    </p>
                    <v-divider class="my-6"></v-divider>
                  </v-col>
                </template>
                <v-col cols="12" v-else-if="ruleMode === brandTypes[2]">
                  <!-- ANTIQUE OPTION -->
                  <v-row dense>
                    <v-col cols="6">
                      <b>Marca</b>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <b>Antigüedad</b>
                    </v-col>
                  </v-row>
                  <v-row dense v-for="i in brand.selected" :key="i.marca">
                    <v-col cols="6" md="9"
                      ><v-badge color="primary" :content="i.marca" bottom>
                        {{ i.marca_descripcion }}
                      </v-badge></v-col
                    >
                    <v-col cols="6" md="3" class="align-center">
                      <v-text-field
                        v-model="i.antique"
                        type="number"
                        dense
                        :rules="[(v) => !!v || 'Campo requerido.']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="my-6"></v-divider>
                </v-col>
                <!-- FILTERS -->
                <v-col cols="12" v-if="types.selected">
                  <v-label>Establecer regla por:</v-label>
                  <v-fade-transition mode="out-in">
                    <v-radio-group
                      v-model="filter.selected"
                      row
                      :rules="filter.rules"
                      v-if="!filter.loading"
                    >
                      <v-radio
                        v-for="i in filterItems"
                        :key="i._id"
                        :label="i.descripcion"
                        :value="i.valor"
                      ></v-radio>
                    </v-radio-group>
                    <v-progress-linear v-else indeterminate></v-progress-linear>
                  </v-fade-transition>
                </v-col>
              </v-row>
              <div class="text-center">
                <v-btn color="primary" text large @click="addRuleToList()"
                  >Anexar Regla<v-icon>mdi-arrow-down</v-icon></v-btn
                >
              </div>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="no-elevation">
          <v-expansion-panel-header :class="panelClasses">
            Reglas registradas
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="headers"
              :items="form"
              class="elevation-0 mt-6"
              :loading="loading"
              :search="search"
            >
              <template #item.compania="{ item }">
                {{ item.compania_descripcion ?? item.compania ?? "" }}
              </template>
              <template #item.producto="{ item }">
                {{ item.producto_descripcion ?? item.producto ?? "" }}
              </template>
              <template #item.tipo="{ item }">
                {{ item.tipo_descripcion ?? item.tipo ?? "" }}
              </template>
              <template #item.filtro="{ item }">
                {{ item.filtro_descripcion ?? item.filtro ?? "" }}
              </template>
              <template #item.actions="{ item, index }">
                <v-btn color="primary" fab text x-small @click="openRule(item)">
                  <v-icon small> mdi-eye</v-icon>
                </v-btn>
                <v-btn
                  color="error"
                  x-small
                  fab
                  text
                  @click="removeRulesFromList(index)"
                >
                  <v-icon small>mdi-trash-can</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-dialog
        v-model="dialog"
        max-width="350"
        @click:outside="dialog = false"
      >
        <v-card>
          <v-card-title class="headline"
            >{{
              selected.value?.producto_descripcion ??
              selected.value?.producto ??
              ""
            }}
            <v-spacer></v-spacer>
            <v-btn color="primary" x-small fab text @click="dialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            ></v-card-title
          >
          <v-card-text>
            <v-data-table
              :headers="selected.headers"
              :items="selected.parsedData ?? []"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "pricingRulesForm",
  props: {
    panelClasses: {
      type: String,
      default: "",
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    channel: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: false,
    panels: [0, 1],
    form: [],
    items: [],
    ruleItems: [],
    filterItems: [],
    brandItems: [],
    noRuleFound: false,
    ruleMode: "",
    company: {
      label: "Compañía",
      selected: null,
      rules: [(v) => !!v || "Seleccione una compañía."],
    },
    product: {
      label: "Producto",
      selected: null,
      rules: [(v) => !!v || "Seleccione un producto."],
    },
    types: {
      label: "Tipo de regla",
      selected: null,
      rules: [(v) => !!v || "Seleccione un tipo de regla."],
    },
    brand: {
      label: "Marca",
      loading: false,
      selected: [],
    },
    models: {
      label: "Modelos",
      selected: null,
      selectedByBrand: [],
      error: "",
    },
    filter: {
      label: "Establecer regla por:",
      selected: null,
      loading: false,
      rules: [(v) => !!v || "Debe haber al menos un filtro seleccionado."],
    },
    search: "",
    selected: {
      value: null,
      headers: [],
      type: "",
    },
    dialog: false,
  }),
  watch: {
    form: {
      deep: true,
      handler() {
        if (!this.loading) {
          this.$emit("change");
        }
      },
    },
  },
  created() {
    this.setup();
  },
  computed: {
    brandTypes() {
      return ["MARCA", "MARCA_MODELO", "MARCA_ANTIGUEDAD"];
    },
    headers() {
      return [
        {
          text: "Compañía",
          align: "start",
          filterable: true,
          sortable: true,
          value: "compania",
        },
        {
          text: "Producto",
          align: "start",
          filterable: true,
          sortable: true,
          value: "producto",
        },
        {
          text: "Tipo",
          align: "start",
          filterable: true,
          sortable: true,
          value: "tipo",
        },
        {
          text: "Filtro",
          align: "start",
          filterable: true,
          sortable: true,
          value: "filtro",
        },
        {
          text: "",
          align: "end",
          filterable: false,
          sortable: false,
          value: "actions",
        },
      ];
    },
  },
  methods: {
    ...mapActions("Channels", [
      "REQUEST_BRANDS",
      "REQUEST_MODELS",
      "REQUEST_TYPE_PRICING_RULES",
      "REQUEST_FILTER_PRICING_RULES",
      "REQUEST_CHANNEL_PRICING_RULES",
      "REQUEST_CHANNEL_PRICING_RULES_DATA",
    ]),

    setup() {
      this.loading = true;
      this.REQUEST_CHANNEL_PRICING_RULES(this.channel)
        .then(async (response) => {
          this.ruleItems = await this.REQUEST_TYPE_PRICING_RULES();
          this.form = await this.REQUEST_CHANNEL_PRICING_RULES_DATA(
            this.channel
          );
          this.items = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setupData(formData) {
      this.form = formData;
    },
    resetForm() {
      this.form = [];
    },
    async setupRules(ruleType) {
      this.noRuleFound = true;
      if (this.brandTypes.includes(ruleType)) {
        switch (ruleType) {
          case this.brandTypes[1]: {
            this.brand.selected = "";
            break;
          }
          default: {
            this.brand.selected = [];
            break;
          }
        }

        this.ruleMode = ruleType;
        this.filter.loading = true;
        this.REQUEST_FILTER_PRICING_RULES()
          .then((response) => {
            this.filterItems = response;
          })
          .finally(() => {
            this.filter.loading = false;
          });
        this.brand.loading = true;
        this.REQUEST_BRANDS()
          .then((response) => {
            this.brandItems = response;
          })
          .then(() => {
            this.brand.loading = false;
          });
        this.noRuleFound = false;
      }
    },
    addRuleToList() {
      this.models.error = "";
      if (!this.$refs.brandModelField?.validate()) {
        this.models.error =
          "Debe agregar al menos agregar una combinación de marca-modelo a la lista.";
      }
      if (this.$refs.pricingForm.validate()) {
        let data = [];
        switch (this.ruleMode) {
          case this.brandTypes[0]: {
            data = this.brand.selected.map((e) => {
              return e.marca;
            });
            break;
          }
          case this.brandTypes[1]: {
            data = this.models.selectedByBrand.map((e) => {
              return `${e.marca}_${e.modelo}`;
            });
            break;
          }
          case this.brandTypes[2]: {
            data = this.brand.selected.map((e) => {
              return `${e.marca}_${e.antique}`;
            });
            break;
          }
        }
        const formData = {
          tipo_regla: "tarificacion",
          producto: this.product.selected,
          tipo: this.types.selected,
          filtro: this.filter.selected,
          data,
        };
        this.form.push(formData);
      }
    },
    removeRulesFromList(index) {
      this.form.splice(index, 1);
    },
    async openRule(item) {
      this.selected.value = item;
      this.selected.headers = [
        {
          text: "Marca",
          value: "marca",
        },
      ];

      switch (item.tipo) {
        case this.brandTypes[0]: {
          this.selected.parsedData = await item.data.map((e) => ({
            marca: e,
          }));
          break;
        }
        case this.brandTypes[1]: {
          this.selected.headers.push({
            text: "Modelo",
            value: "modelo",
          });
          this.selected.parsedData = await item.data.map((e) => {
            let items = e.split("_");
            return {
              marca: items[0],
              modelo: items[1],
            };
          });
          break;
        }
        case this.brandTypes[2]: {
          this.selected.headers.push({
            text: "Antigüedad",
            value: "antique",
          });
          this.selected.parsedData = await item.data.map((e) => {
            let items = e.split("_");
            return {
              marca: items[0],
              antique: items[1],
            };
          });
          break;
        }
      }

      this.dialog = true;
    },
    addModelsToList() {
      if (this.brand.selected && this.models.selected) {
        if (
          this.models.selectedByBrand.some(
            (obj) =>
              obj.marca === this.brand.selected.marca &&
              obj.modelo === this.models.selected.modelo
          )
        ) {
          this.snackbar(
            "Ya ha agregado esta combinación a la lista.",
            "warning"
          );
        } else {
          this.models.selectedByBrand.push({
            marca: this.brand.selected.marca,
            marca_descripcion: this.brand.selected.marca_descripcion,
            modelo: this.models.selected.modelo,
            modelo_descripcion: this.models.selected.modelo_descripcion,
          });
        }
      }
    },
    removeFromModelsList(index) {
      this.models.selectedByBrand.splice(index, 1);
    },
    snackbar(message = "", type = "error") {
      this.$store.commit("setSnackbar", {
        active: true,
        text: message,
        top: true,
        right: true,
        color: type,
      });
    },
  },
};
</script>

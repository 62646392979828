<template>
  <form-modal v-bind="bindChain.form" v-on="onChain.form">
    <v-form :ref="formRef">
      <!-- <v-file-input
        label="Cargar archivo de privacidad (PDF)"
        accept="application/pdf"
        :rules="[
          (value) => !!value || 'El archivo es requerido.',
          (value) => isValidPDF(value) || 'Solo se aceptan archivos PDF',
        ]"
        v-model="selectedFile"
        @change="handleFileChange"
      ></v-file-input> -->
      <v-row dense>
        <v-col cols="12" md="6">
          <v-combobox
            label="Prefijo"
            v-model="form[keys.privacy.subtitle]"
            :items="options"
            clearable
            hide-selected
            persistent-hint
            hint="Puede seleccionar o escribir un texto previo para la política."
          ></v-combobox>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            name="nombre"
            label="Título de la pólitica"
            v-model="form[keys.privacy.title]"
            :rules="[(v) => !!v || 'El campo título es requerido.']"
            persistent-hint
            hint="El nombre de la política de privacidad."
            ref="textarea"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <quill-editor
            v-model="form[keys.privacy.message]"
            :options="editorOptions"
            ref="quillEditor"
          ></quill-editor>
        </v-col>
      </v-row>
    </v-form>
  </form-modal>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";
import { advanceConfigKeys as Keys } from "@/store/resources/channelkeys.js";
import * as PrivacyOptions from "./privacy.json";

import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "PrivacyFormModal",
  mixins: [FormMixin],
  components: {
    quillEditor,
  },
  data: () => ({
    width: 800,
    defaultTitle: "Formulario de privacidad",
    form: {
      [Keys.privacy.subtitle]: "",
      [Keys.privacy.title]: "",
      [Keys.privacy.message]: "",
      // [Keys.privacy.doc]: {
      //   [Keys.privacy.doc_title]: "",
      //   [Keys.privacy.doc_type]: "application/pdf",
      //   [Keys.privacy.file]: null,
      // },
    },
    editorOptions: {
      placeholder: "Escribe tu política aquí...",
      theme: "snow",
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline"],
          ["link", "blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
        ],
      },
    },
    // selectedFile: null,
  }),
  computed: {
    keys: () => Keys,
    options: () => PrivacyOptions.data,
  },
  methods: {
    // isValidPDF(file) {
    //   const regex = /\.pdf$/i;
    //   return file && regex.test(file.name);
    // },
    submit() {
      if (this.$refs[this.formRef].validate()) {
        const id = this.formData?.id;
        const formData = this.form;
        if (typeof id !== undefined) {
          formData.id = id;
        }
        this.$emit("submit", this.form);
      }
    },
    reset() {
      this.form[this.keys.privacy.message] = "";
      this.form[this.keys.privacy.subtitle] = "";
      this.form[this.keys.privacy.title] = "";
      // this.form[this.keys.privacy.doc][this.keys.privacy.doc_title] = "";
      // this.form[this.keys.privacy.doc][this.keys.privacy.doc_type] =
      //   "application/pdf";
      // this.form[this.keys.privacy.doc][this.keys.privacy.file] = null;
      // this.selectedFile = null;
    },
    // handleFileChange(file) {
    //   if (file) {
    //     this.form[this.keys.privacy.doc][this.keys.privacy.doc_type] =
    //       file.type;
    //     if (!this.form[this.keys.privacy.doc][this.keys.privacy.doc_title]) {
    //       this.form[this.keys.privacy.doc][this.keys.privacy.doc_title] =
    //         file.name;
    //     }
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       this.form[this.keys.privacy.doc][this.keys.privacy.file] =
    //         e.target.result;
    //     };
    //     reader.readAsDataURL(file);
    //   } else {
    //     this.form[this.keys.privacy.doc][this.keys.privacy.file] = null;
    //   }
    // },
  },
};
</script>
